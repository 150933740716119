import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { TestimonialsModel } from "../../../models/testimonials-modules/testimonials.model";
/* components */
import { ModalTestimonialsComponent } from "../modal-testimonials/modal-testimonials.component";

@Component({
  selector: 'app-card-testimonials',
  templateUrl: './card-testimonials.component.html',
  styleUrls: ['./card-testimonials.component.scss']
})
export class CardTestimonialsComponent implements OnInit {
  @Input() testimonial: TestimonialsModel;
  modalOption: NgbModalOptions = {};
  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }

  modalTestimony(testimony){
    this.modalOption.backdrop = 'static';
    this.modalOption.size = 'lg';
    this.modalOption.scrollable = false;
    this.modalOption.centered = true;
    const modalRef = this.modalService.open(ModalTestimonialsComponent, this.modalOption);
    modalRef.componentInstance.testimony = testimony;
    modalRef.result.then(
      () => {
        
      },
      ()=> {
        
    });    
  }


}
