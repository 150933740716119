import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { PersonalDataModel } from '../../models/profile/profile.model';
import { BusinessDataModel } from '../../models/profile/profile.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private readonly url: string;

  constructor(
    private http: HttpClient,
  ) {
    this.url = environment.url;
  }


  getPersonalData(): Observable<PersonalDataModel> {
    const url = `${this.url}/api/personal_data/`;
    return this.http.get<PersonalDataModel>(url)
  }


  patchPersonalData(personal_data: any): Observable<PersonalDataModel> {
    const url = `${this.url}/api/personal_data/`;
    return this.http.patch<PersonalDataModel>(url, personal_data)
  }


  getBusinesslData(): Observable<BusinessDataModel> {
    const url = `${this.url}/api/business_data/`;
    return this.http.get<BusinessDataModel>(url)
  }


  patchBusinesslData(business_data: any): Observable<BusinessDataModel> {
    const url = `${this.url}/api/business_data/`;
    return this.http.patch<BusinessDataModel>(url, business_data)
  }

}
