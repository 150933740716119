<div class="container">
    <div class="row justify-content-center align-items-center align-self-center">
        <div class="col col-12 col-md-5" *ngIf="ally.section_image">
            <img [src]="ally.section_image | safeHtml: 'url'" loading="lazy" class="img" alt="image">
        </div>
        <div class="col col-12 col-md-7 text-center header-box" *ngIf="ally.section_text">
            <div [innerHTML]="ally.section_text | safeHtml: 'html'"></div>
        </div>
    </div>
</div>
