<div class="card-lesson">
 
  <img class="card-lesson-img"
       (click)="navigateToDetail(lesson)"
       [src]="lesson.image | safeHtml:'url'" alt="Card image cap">
  
  <div class="card-body">

    <a class="pointer" (click)="navigateToDetail(lesson)">

    <div class="row">
      <div class="col">
       
        <a class="title-lesson">
        {{lesson.title}}
        </a>
      
      </div>
    </div>

    <div class="row">
      <div class="col-8">
        <p><span class="dot" style="background-color: {{lesson.module_color}}"></span>{{lesson.module_name}}</p>
      </div>

      <div class="col-4">
        <p class="float-right">{{lesson.time}}</p>
      </div>
    </div>

  </a>

   <div class="row">
      <div class="col-10">
        <a class="pointer" (click)="navigateToDetail(lesson)">
        <div class="progress" *ngIf="lesson.percentage != 0">
          <div class="progress-bar bg-success" role="progressbar" style="width: {{lesson.percentage}}%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        </a>
      </div>

      <div class="col-2">
          <span class="favicon">
            <fa-icon [icon]="icon.fav" *ngIf="!lesson.favorite"
                     (click)="favoriteLesson(lesson)"></fa-icon>
            <fa-icon [icon]="icon.fav_solid" *ngIf="lesson.favorite"
                     (click)="favoriteLesson(lesson)"></fa-icon>
          </span>
      </div>
    </div>

    <div class="row">
      <div class="col-7">
          <span class="favicon" *ngIf="lesson.rating == 0">
            <fa-icon [icon]="iconStar.fav"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
          </span>
          <span class="favicon" *ngIf="lesson.rating == 0.5">
            <fa-icon [icon]="iconStar.fav_half"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
          </span>
          <span class="favicon" *ngIf="lesson.rating == 1">
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
          </span>
          <span class="favicon" *ngIf="lesson.rating == 1.5">
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_half"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
          </span>
          <span class="favicon" *ngIf="lesson.rating == 2">
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
          </span>
          <span class="favicon" *ngIf="lesson.rating == 2.5">
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_half"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
          </span>
          <span class="favicon" *ngIf="lesson.rating == 3">
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
          </span>
          <span class="favicon" *ngIf="lesson.rating == 3.5">
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_half"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
          </span>
          <span class="favicon" *ngIf="lesson.rating == 4">
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav"></fa-icon>
          </span>
          <span class="favicon" *ngIf="lesson.rating == 4.5">
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_half"></fa-icon>
          </span>
          <span class="favicon" *ngIf="lesson.rating == 5">
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
            <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
          </span>
      </div>
      <div class="col-5" style="text-align: left;">
        <span class="favicon-users">
          {{lesson.users_who_rated}} <fa-icon [icon]="iconUser"></fa-icon>
        </span>
      </div>
    </div>

  </div>
</div>