import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {switchMap} from "rxjs/operators";
import {Observable} from "rxjs";
import {BlogResponseModel} from "../../../models/blog/blog.model";
import {BlogService} from "../../../services";

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent implements OnInit {

  detailBlog$: Observable<BlogResponseModel>;

  constructor(
    private route: ActivatedRoute,
    private blogService: BlogService
  ) { }

  ngOnInit(): void {
    this.detailBlog$ = this.route.params
      .pipe(
        switchMap((params: Params) => this.blogService.getBlogBySlug(params.slug))
      )
  }
}
