export const metaData = {
  "@context":"https://schema.org",
  "@graph":[
    {
      "@type":"Organization",
      "@id":"https://betechcapital.com/#organization",
      "name":"BeTech Capital",
      "url":"https://betechcapital.com/",
      "sameAs":[

      ],
      "logo":{
        "@type":"ImageObject",
        "@id":"https://betechcapital.com/#logo",
        "inLanguage":"es",
        "url":"https://betechcapital.com/wp-content/uploads/2019/10/beTech-Capital_logo.jpeg",
        "width":326,
        "height":127,
        "caption":"BeTech Capital"
      },
      "image":{
        "@id":"https://betechcapital.com/#logo"
      }
    },
  ]
}
