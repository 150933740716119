<div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span class="color-close" aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <ngx-spinner
    bdColor="rgba(255,255,255,0.8)"
    size="large"
    color="#215aa8"
    [fullScreen]="true"
    type="ball-atom">
    <p class="title-loader">El reporte se esta generando con éxito.<p>
    <small class="body-loader">Sé paciente podría tardar hasta 5 minutos.</small>
    </ngx-spinner>

    <div class="container text-center" *ngIf="shopMode === 'true' && isAcelera === false  && isVideo === false && isCertificate === false; else elseBlock">
        <div class="row">
            <div class="col">
                <img class="logo-achivement" src="{{dataAchievement?.image}}" alt="Foto">
            </div>
        </div>
        <br>
        <h3 class="congrats-text affogato-bold" style="color: #1294D6;">¡Felicidades!</h3>
        <p style="font-weight: bold;">Has ganado</p>
        <div class="col title-points-container">
            <h1 class="title" style="color: white !important;">{{dataAchievement?.name}}</h1>
        </div>
        <p>{{dataAchievement?.reward_one_text}}</p>
        <br>
        <p style="font-weight: bold; font-size: small;color: #727272;">Sigue ganando llaves para poder canjear más premios.</p>
        <div class="row">

            <!--Botón de Premio-->
            
            <div class="col col-12 text-center" *ngIf="dataAchievement?.unblocked">
                <hr *ngIf="dataAchievement?.keystring && !dataAchievement?.certificate">
                <a class="btn btn-lg" *ngIf="dataAchievement?.keystring && !dataAchievement?.certificate" (click)="loadPrize(dataAchievement?.id)">Abrir premio</a>
            </div> 

        </div>


    </div>

    <ng-template #elseBlock>
        <div class="container text-center">
            <h5 *ngIf="dataAchievement?.unblocked" class="modal-title" id="modal-basic-title">¡Logro desbloqueado!</h5>
            <h5 *ngIf="!dataAchievement?.unblocked" class="modal-title" id="modal-basic-title">¡Logro bloqueado!</h5>
            <br>
            <div class="row">
                <div class="col">
                    <img class="logo-achivement" src="{{dataAchievement?.image}}" alt="Foto">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <p *ngIf="!dataAchievement?.unblocked && dataAchievement?.type == 'personal_data'">Actualiza los datos de tu perfil para desbloquear este logro.</p>
                    <p *ngIf="!dataAchievement?.unblocked && dataAchievement?.type == 'business_data'">Actualiza los datos de tu negocio para desbloquear este logro.</p>
                    <p *ngIf="!dataAchievement?.unblocked && dataAchievement?.type == 'module'">Completa {{dataAchievement?.lessons_to_complete}} lecciones de {{dataAchievement?.module_name}} para desbloquear este logro.</p>
                </div>

                <!--Botón de Premio-->
                
                <div class="col col-12 text-center" *ngIf="dataAchievement?.unblocked">
                    <hr *ngIf="dataAchievement?.keystring && !dataAchievement?.certificate">
                    <a class="btn btn-lg" *ngIf="dataAchievement?.keystring && !dataAchievement?.certificate" (click)="loadPrize(dataAchievement?.id)">Abrir premio</a>
                </div>
                 
                <!--Botones de rompensas-->
                <div class="col col-12 text-center" *ngIf="dataAchievement?.unblocked">
                    <p *ngIf="dataAchievement?.reward_one_text"> {{dataAchievement?.reward_one_text}} </p>
                    <img class="reward-preview" *ngIf="dataAchievement?.reward_one_preview && isVideo == false" src="{{dataAchievement?.reward_one_preview}}" alt="Recompensa 1">
                    <a class="btn btn-lg" *ngIf="dataAchievement?.reward_one && dataAchievement.module_name != 'Acelera con Coppel'" href='{{dataAchievement?.reward_one}}' target="_blank">Abrir recompensa 1</a>
                    <hr *ngIf="dataAchievement?.reward_one_text && dataAchievement?.reward_one">
                    <p *ngIf="dataAchievement?.reward_two_text"> {{dataAchievement?.reward_two_text}}</p>
                    <img class="reward-preview" *ngIf="dataAchievement?.reward_two_preview" src="{{dataAchievement?.reward_two_preview}}" alt="Recompensa 2">
                    <a class="btn btn-lg" *ngIf="dataAchievement?.reward_two && dataAchievement.module_name != 'Acelera con Coppel'" href='{{dataAchievement?.reward_two}}' target="_blank">Abrir recompensa 2</a>
                </div>
    
                <!--Botón de diploma-->
    
                <div class="col col-12 text-center" *ngIf="dataAchievement?.unblocked">
                    <hr *ngIf="dataAchievement?.certificate">
                    <a class="btn btn-lg" *ngIf="dataAchievement?.certificate" (click)="loadCerticate(dataAchievement?.certificate)">Abrir Diploma</a>
                </div>
    
    
            
    
                <div class="col col-12" *ngIf="dataAchievement?.unblocked && dataAchievement?.reference == 'acelera'">
                    <form (ngSubmit)="getPDF(latitude, longitude)" [formGroup]='aceleraForm' novalidate>
                        <div class="form-group">
                            <label>Nombre de tu negocio*</label>
                            <input class="form-control" 
                                    type="text" 
                                    placeholder="Ingresa el nombre de tu negocio"
                                    formControlName='business_name'
                                    [readonly]="disableForm">
                            <small class="text-danger" *ngIf="aceleraForm.get('business_name').hasError('required') && aceleraForm.get('business_name').touched">
                                El campo es requerido
                            </small>
                        </div>
                        <div class="form-group">
                            <label for="status">Giro de negocio*</label>
                            <ng-select
                                placeholder="Selecciona el giro de tu negocio"
                                formControlName='ally_business_type'
                                (change)="selectBussinesType($event)"
                                [readonly]="disableForm">
                                <ng-option *ngFor="let item of arrayAchievementsUserData" [value]="item.id">
                                     {{item.name}}
                                </ng-option>
                            </ng-select>
                            <small class="text-danger" *ngIf="aceleraForm.get('ally_business_type').hasError('required') && aceleraForm.get('ally_business_type').touched">
                                El campo es requerido
                            </small>
                        </div>
                    
                        <div class="form-group">
                            <label>Ingresa tu dirección (calle y número), después selecciona una de las opciones.</label>
                            <input 
                            class="form-control" 
                            type="text" 
                            formControlName='address' 
                            #agmSearch 
                            (keydown.enter)="$event.preventDefault()" 
                            placeholder="Buscar la dirección más cercana" 
                            autocorrect="off" 
                            autocapitalize="off" 
                            spellcheck="off"
                            [readonly]="disableForm">
                            <small class="text-danger" *ngIf="aceleraForm.get('address').hasError('required') && aceleraForm.get('address').touched">
                                El campo es requerido
                            </small>
                        </div>
                    
                        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                            <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                                (dragEnd)="markerDragEnd($event)"></agm-marker>
                        </agm-map>
    
                        <div class="row buttons">
                            <div class="col-lg-6 col-12 col-md-6">
                                <button *ngIf="!disableButton" class="btn btn-lg" type="submit">Enviar dirección</button>
                            </div>
                            <div class="col-lg-6 col-12 col-md-6">
                                <a class="btn btn-lg" [class.disabled]="!disableButton" href='{{urlPDF}}' target="_blank">Descargar reporte</a>
                            </div>
                        </div>
                    </form>
                </div>
                
                <div class="col col-12 text-center">
                    <a class="btn" *ngIf="!dataAchievement?.unblocked && dataAchievement?.type == 'module'" (click)="goModule(dataAchievement?.module)">Ir a {{dataAchievement?.module_name}}</a>
                    <a class="btn" *ngIf="!dataAchievement?.unblocked && dataAchievement?.type == 'personal_data' || dataAchievement?.type == 'business_data'" (click)="goProfile()">Ir a perfil</a>
                </div>  
    
            </div>
    
    
        </div>
    </ng-template>
</div>