import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CatalogService, ProfileService, SweetAlertService } from "../../../services/index";
import { QuestionsBussinesModel } from 'src/app/models/profile/profile.model';
import { FormGroup, FormBuilder, FormControl, FormArray } from "@angular/forms";
import { TypeSweet } from '../../../utils/typeSweet';
declare const fbq: any;

@Component({
  selector: 'app-modal-survey',
  templateUrl: './modal-survey.component.html',
  styleUrls: ['./modal-survey.component.scss']
})
export class ModalSurveyComponent implements OnInit {
  arrayBusinessImprovements: Array<QuestionsBussinesModel> = [];
  formSurvey: FormGroup;
  isDisabled:boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private sweetAlert: SweetAlertService,
    public _catalogService: CatalogService,
    private _profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    this.loadBusinessImprovements();
    this.loadForm();
  }

  loadForm(){
    this.formSurvey = this.fb.group({
      business_improvements: this.fb.array([]),
      business_improvements_modal: new FormControl('')
    })
  }

  loadBusinessImprovements(){
    this._catalogService.getBusinessImprovements()
    .subscribe(res => {
      this.arrayBusinessImprovements = res;
    })
  }

  checkOption(isChecked:boolean, businessImprovements:QuestionsBussinesModel){
    const FormArray = <FormArray>this.formSurvey.controls.business_improvements;
    if (isChecked) {
      FormArray.push(new FormControl(businessImprovements.id));
    } else {
      let index = FormArray.controls.findIndex(x => x.value.id == businessImprovements.id)
      FormArray.removeAt(index);
    }
    if(this.formSurvey.get('business_improvements').value.length > 0){
      this.isDisabled = false;
    }else{
      this.isDisabled = true;
    }
  }

  sendSurvey(){
    this.formSurvey.get('business_improvements_modal').setValue(true);
    console.log(this.formSurvey.value)
    this._profileService.patchBusinesslData(this.formSurvey.value).subscribe(
      res =>
      {
        fbq('track', 'CompleteRegistration'); 
        this.sweetAlert.basicSweet('Guardado', 'Datos guardados exitosamente', TypeSweet.success);
        this.activeModal.close();
      }
      , error => {
        this.sweetAlert.basicSweet('Error', 'Por favor, vuelve a intentarlo', TypeSweet.error);
      }
    )
  }

  closeModal(){
    this.formSurvey.get('business_improvements_modal').setValue(true);
    console.log(this.formSurvey.value)
    this._profileService.patchBusinesslData(this.formSurvey.value).subscribe(
      res =>
      {
        fbq('track', 'CompleteRegistration'); 
        this.activeModal.dismiss();
      }
      , error => {
        this.sweetAlert.basicSweet('Error', 'Por favor, vuelve a intentarlo', TypeSweet.error);
      }
    )
  }

}
