import { Component, OnInit } from '@angular/core';
import {ICON} from "../../../utils/icons";
import { environment } from "../../../../environments/environment";
import {DATA_LOCALSTORAGE} from "../../../utils/dataLocalstorage";
import {ActivatedRoute, Router} from "@angular/router";
import { CookieService } from 'ngx-cookie';
import {AllyService} from "../../../services";
import {AllyModel} from "../../../models/ally/ally.model";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  s3UrlImage: string = `${environment.url_S3assets}`;
  icons: {facebook: any, youtube: any, instagram:any, mail: any, web: any} = {
    facebook: ICON.FACEBOOK,
    youtube: ICON.YOUTUBE,
    instagram: ICON.INSRAGRAM,
    mail: ICON.MAIL,
    web: ICON.WEB
  }
  allyDetail: AllyModel;
  brand:string;
  country:string;
  landingTemplate:string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private allyService: AllyService,
    private _cookieService: CookieService,
  ) { 
    this.route.paramMap.subscribe(params => {
      this.country = params.get('country');
      this.brand = params.get('brand');
      this._cookieService.put(DATA_LOCALSTORAGE.COUNTRY, this.country)
      this._cookieService.put(DATA_LOCALSTORAGE.CODE_ALLY, this.brand)
    })
  }

  ngOnInit(): void {
    this.loadDataAlly(this.brand);
    console.log("Icons", this.icons);
  }

  loadDataAlly(brand: string) {
    if (brand === 'enko') {
      this.allyDetail = null;
    }else{
      this.allyService.getInfoOfAlly(brand).subscribe(
        (res:AllyModel) => {
          this.landingTemplate = res.landing_template;
          this.allyDetail = res;
        },error=>{
        }
      )
    }
  }

}
