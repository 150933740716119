<div class="container-fluid">
  <div class="row justify-content-center align-items-center align-self-center">
    <div *ngIf="!ally" class="col col-12 col-md-5">
      <img [src]="dataCourses.image | safeHtml: 'url'" loading="lazy" class="img" alt="image">
    </div>
    <div class="col col-12 col-md-{{ally ? '12' : '7'}} text-center">
      <p class="title-curse affogato-bold" *ngIf="!ally">{{dataCourses.title}}</p>
      <p class="title-curse affogato-bold" *ngIf="ally && (landingTemplate != 'coppel' && ally.code != 'oleoductovivo' && ally.code != 'banorte')">{{dataCourses.title}}</p>
      <p class="title-curse affogato-bold" *ngIf="ally && landingTemplate == 'coppel'">{{dataCourses.title_coppel}}</p>
      <p class="title-curse affogato-bold" *ngIf="ally && ally.code === 'oleoductovivo'">{{dataCourses.title_oleoducto}}</p>
      <p class="title-curse affogato-bold" *ngIf="ally && ally.code === 'banorte'">{{dataCourses.title_banorte}}</p>
      <div class="row justify-content-center">
        <div class="col col-4" *ngFor="let item of dataCourses.courses">
          <img [src]="item.img | safeHtml: 'url'" loading="lazy" class="img-circule" alt="item.text">
          <p class="text-img affogato-medium">{{item.text}}</p>
        </div>
      </div>
      <!-- <div class="row justify-content-center" *ngIf="ally && ally.name === 'Comex'">
        <div class="col col-4" *ngFor="let item of dataCourses.courses_comex">
          <img [src]="item.img | safeHtml: 'url'" class="rounded-circle img-circule " alt="item.text">
          <p class="text-img affogato-medium">{{item.text}}</p>
        </div>
      </div> -->
    </div>
  </div>
</div>