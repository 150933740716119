import { Component } from '@angular/core';
import {CanonicalService} from "./services";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {metaData} from "./core/structureData/metaData";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  productLD = metaData;

  html: SafeHtml;

  constructor(
    private canonicalService: CanonicalService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.html = this.getSafeHTML(this.productLD);
    this.canonicalService.setCanonicalURL();
  }

  getSafeHTML(jsonLD: {[key: string]: any}): SafeHtml {
    const json = jsonLD ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>') : ''; // escape / to prevent script tag in JSON
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
