import { Component, Inject, Input, OnInit, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { ICON } from "../../../utils/icons";
import { ActivatedRoute, Router } from "@angular/router";
import { AllyService, StorageService } from "../../../services";
import { CookieService } from 'ngx-cookie';
import { AllyModel } from "../../../models/ally/ally.model";
import { environment } from "../../../../environments/environment";
import { ShopService } from 'src/app/services/shop/shop.service';
import { DATA_LOCALSTORAGE } from 'src/app/utils/dataLocalstorage';

@Component({
  selector: 'app-header-auth',
  templateUrl: './header-auth.component.html',
  styleUrls: ['./header-auth.component.scss']
})
export class HeaderAuthComponent implements OnInit {
  @Input() allyDetail:AllyModel;
  s3UrlImage: string = `${environment.url_S3assets}`;
  country:string;
  brand:string;
  shopPoints: number = 0;
  shopMode:string;
  icons: { close: any, key: any } = {
    close: ICON.CLOSE,
    key: ICON.KEY,
  }

  landingTemplate:string;

  menuOptions: Array<{ link: string, icon: any, name: string }> = [];

  menuWithAuth: Array<{ link: string, icon: any, name: string }> = [
    {
      link: './inicio',
      icon: ICON.HOME,
      name: 'Inicio'
    },
    {
      link: './lecciones',
      icon: ICON.LAYER,
      name: 'Lecciones'
    },
    {
      link: './logros',
      icon: ICON.TROPHY,
      name: 'Mis logros'
    },
    {
      link: './perfil',
      icon: ICON.USER,
      name: 'Mi perfil'
    }
  ]

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private route: ActivatedRoute,
    private router: Router,
    private _cookieService: CookieService,
    private _storageService: StorageService,
    private _shopService: ShopService,
    private allyService: AllyService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.allyDetail = changes.allyDetail.currentValue
  }

  ngOnInit(): void {
    this.shopMode = this._cookieService.get(DATA_LOCALSTORAGE.SHOP_MODE);
    this.route.paramMap.subscribe(params => {
      this.country = params.get('country');
      this.brand = params.get('brand');
      this.getInfoAlly();
    })
    this.drawMenu();
    if(this.shopMode === 'true') {
      this.getUserShopPoints();
      this.updateEventSharedService();
    }
  }


  drawMenu() {
    if (this.shopMode === 'true') {
      this.menuWithAuth = [
        {
          link: './inicio',
          icon: ICON.HOME,
          name: 'Inicio'
        },
        {
          link: './lecciones',
          icon: ICON.LAYER,
          name: 'Lecciones'
        },
        {
          link: './logros',
          icon: ICON.STORE,
          name: 'Tienda'
        },
        {
          link: './perfil',
          icon: ICON.USER,
          name: 'Mi perfil'
        }
      ]
    }
    this.menuOptions = this.menuWithAuth;
    console.log("Menu options", this.menuOptions);
  }

  closeSession() {
    this._cookieService.removeAll();
    this._storageService.deleteAll();
    this.drawMenu();
    if (this.brand !== 'enko' && this.brand) {
      this.router.navigate([`/${this.country}/${this.brand}`]);
    } else {
      this.router.navigate([`/${this.country}/enko`]);
    }
  }

  getUserShopPoints(): void {
    this._shopService.getUserShopPoints().subscribe(
      res =>{
        console.log('SHOP POINTS:', res);
        this.shopPoints = res.shop_points;
      }
    )
  }

  /**
   * Services Shared for update any Component
   */
  updateEventSharedService(){
    this._shopService.changeEvent.subscribe(change => {
      console.log('EVENT EMITTER....');
      this.getUserShopPoints();
    })
  }

  getInfoAlly() {
    this.allyService.getInfoOfAlly(this.brand).subscribe(res => {
      this.landingTemplate = res.landing_template;
      console.log('oka');
    },error=>{
      console.log(error);
    });
  }


}
