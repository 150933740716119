import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { CatalogService, LessonService, SweetAlertService, StorageService } from "../../../services/index";
import { TypeSweet } from '../../../utils/typeSweet';
import { ReviewLessonModel } from "../../../models/review-lesson/reviewLesson.model";
import { LessonModel } from "../../../models/lesson/lesson.model";
import { NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { DATA_LOCALSTORAGE } from "../../../utils/dataLocalstorage";
import { CookieService } from 'ngx-cookie';
/* Components */
import {ModalAchievementComponent} from '../../../shared/components/modal-achievement/modal-achievement.component';

@Component({
  selector: 'app-rating-part',
  templateUrl: './rating-part.component.html',
  styleUrls: ['./rating-part.component.scss']
})
export class RatingPartComponent implements OnInit{
  @Input() detailLesson: LessonModel;
  modalOption: NgbModalOptions = {};
  reviewArray: Array<ReviewLessonModel> = [];
  lessonArray: Array<LessonModel> = [];
  Achievements:Array<number> = [];
  finish:boolean = true;
  rating:boolean = true;
  questions:boolean = false;
  nextLesson:boolean = false;
  range:number
  urlShare:string;
  hasNextLesson: boolean = false;
  hasNextLessonUrl: string = '../../lecciones';
  constructor(
    private _catalogService: CatalogService,
    private _lessonService: LessonService,
    private _sweetService: SweetAlertService,
    private _storageService: StorageService,
    private _cookieService: CookieService,
    private modalService: NgbModal,
  ) {
    this.Achievements = this._storageService.get('achievement');
  }

  ngOnInit(): void {
    console.log(window.location.href);
    const currentUrl = window.location.href.split('/');
    const slug = currentUrl[6];
    this.getHasNextLesson(slug);
    setTimeout(() => {
      this.getReviewsLesson();
      this.getNextLeeson();
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.detailLesson = changes.detailLesson.currentValue;
  }

  getUrl(){
    if(this._cookieService.get(DATA_LOCALSTORAGE.COUNTRY) && this._cookieService.get(DATA_LOCALSTORAGE.CODE_ALLY)){
      this.urlShare = `https://www.enko.org/${this._cookieService.get(DATA_LOCALSTORAGE.COUNTRY)}/${this._cookieService.get(DATA_LOCALSTORAGE.CODE_ALLY)}/seccion/${this.detailLesson?.slug}/${this.detailLesson?.introduction}`;
    }else{
      this.urlShare = `https://www.enko.org/mx/enko/seccion/${this.detailLesson.slug}/${this.detailLesson.introduction}`;
    }
    return this.urlShare;
  }

  getReviewsLesson(){
    this._catalogService.getReviewLesson().subscribe(
      (res:Array<ReviewLessonModel>)=>{
        this.reviewArray = res;
      },(error:any)=>{
        console.log(error)
      }
    )
  }

  getNextLeeson(){
    this._lessonService.getNextLesson(this.detailLesson.slug).subscribe(
      (res:Array<LessonModel>)=>{
        this.lessonArray = res;
        if(this.Achievements){
          this.Achievements.forEach(element => {
            this.modalAchievement(element);
          });
        }
      },(error:any)=>{
      }
    )
  }

  getHasNextLesson(slug: string){
    this._lessonService.getDetailLesson(slug).subscribe(
      (resLess: LessonModel)=>{
        if(resLess.has_next_lesson == true){
          this.hasNextLesson = true;
          this._lessonService.getHasNextLesson(slug).subscribe(
            (resHasNextLess: LessonModel)=>{
              this.hasNextLessonUrl = `../../${resHasNextLess.slug}/${resHasNextLess.introduction}`;
              console.log('hasNextLessonUrl: ', this.hasNextLessonUrl);
            }
          )
        }
      }
    )
  }

  omitir(){
    this.rating = false;
    this.questions = false;
    this.nextLesson = true;
  }

  addRatingLesson(review){
    let reviewData = {
      rating: this.range,
      review: review
    }
    this._lessonService.AddRatingLesson(this.detailLesson.slug, reviewData).subscribe(
      (res:any)=>{
        this._sweetService.basicSweet('Registro Exitoso', 'Hemos recibido tu opinión', TypeSweet.success);
        this.rating = false;
        this.questions = false;
        this.nextLesson = true;
      },(error:any)=>{
        this._sweetService.basicSweet('ERROR', '', TypeSweet.error)
      }
    )
  }

  setRange(range:number){
    this.range = range;
    if(this.range == 5){
      this.rating = false;
      this.questions = false;
      this.nextLesson = true;
      this.addRatingLesson(null)
    }else{
      this.rating = false;
      this.questions = true;
      this.nextLesson = false;
    }
  }

  modalAchievement(idAchievement){
    this.modalOption.backdrop = 'static';
    this.modalOption.size = 'sm';
    this.modalOption.scrollable = true;
    this.modalOption.centered = true;
    const modalRef = this.modalService.open(ModalAchievementComponent, this.modalOption);
    modalRef.componentInstance.idAchievement = idAchievement;
    modalRef.result.then(
      () => {
        this._storageService.delete('achievement');
      },
      ()=> {
        this._storageService.delete('achievement');
    });    
  }

}
