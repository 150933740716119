import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {BlogResponseModel} from "../../models/blog/blog.model";
import {PaginatorBlog} from "../../models/paginator/paginator.model";
import {DATA_LOCALSTORAGE} from "../../utils/dataLocalstorage";
import {StorageService} from "../storage/storage.service";
import { CookieService } from 'ngx-cookie';

@Injectable()
export class BlogService {

  private readonly url: string;

  constructor(
    private htpp: HttpClient,
    private cookiesService: CookieService
  ) {
    this.url = environment.url;
  }

  getListBlog(): Observable<PaginatorBlog> {
    const url = `${this.url}/api/${this.cookiesService.get(DATA_LOCALSTORAGE.COUNTRY)}/posts/?page=1`;
    return this.htpp.get<PaginatorBlog>(url)
  }

  getBlogBySlug(slug: string): Observable<BlogResponseModel> {
    const url = `${this.url}/api/${this.cookiesService.get(DATA_LOCALSTORAGE.COUNTRY)}/posts/slug/${slug}/`
    return this.htpp.get<BlogResponseModel>(url);
  }
}
