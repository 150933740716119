<nav class="navbar navbar-expand-lg navbar-dark nav__bg sticky-top justify-content-center">

  <a class="navbar-brand nav--text" [routerLink]="['./inicio']" *ngIf="!allyDetail">
    <img src="{{s3UrlImage}}Logos/logo-enko-gray.png" loading="lazy" class="logo-enko" alt="Logo Enko">
  </a>
  <a class="navbar-brand nav--text" [routerLink]="['./inicio']" *ngIf="allyDetail && allyDetail.navbar_enko_logo_enabled">
    <img src="{{s3UrlImage}}Logos/logo-enko-gray.png" loading="lazy" class="logo-enko" alt="Logo Enko">
  </a>
  <a class="{{landingTemplate == 'coppel' ? 'navbar-brand-ally-coppel': 'navbar-brand-ally'}}" [routerLink]="['inicio']" *ngIf="allyDetail && !allyDetail.second_logo">
    <img [src]="allyDetail.logo" loading="lazy" class="{{landingTemplate == 'coppel' ? 'logo-ally-coppel': 'logo-ally'}}" alt="Enko">
  </a>
  <a class="{{landingTemplate == 'coppel' ? 'navbar-brand-ally-coppel': 'navbar-brand-ally'}}" [routerLink]="['inicio']" *ngIf="allyDetail && allyDetail.second_logo">
    <img [src]="allyDetail.second_logo" loading="lazy" class="{{landingTemplate == 'coppel' ? 'logo-ally-coppel': 'logo-ally'}}" alt="Enko">
  </a>


  <ul class="navbar-nav">
    <li class="nav-item" routerLinkActive="active" *ngFor="let item of menuOptions">
      <a class="nav-link nav--text"
         [routerLink]="[item.link]">
        <fa-icon [icon]="item.icon"></fa-icon>
        <span class="hide-name">{{item.name}}</span>
      </a>
    </li>

    <li class="nav-item" routerLinkActive="active" *ngIf="shopMode == 'true'">
      <a class="nav-link nav--text" routerLink="./logros">
        <span class="hide-name" style="color: #1294D6;">{{shopPoints}}</span>
        <img style="width: 50px; margin-left: 5px;" src="https://static.enko.org/public_files/encoppel/llaves/llave_coppel-01.svg" loading="lazy" class="logo-enko" alt="Logo Enko">
      </a>
    </li>

    <!-- <ng-template [ngIf]="menuOptions.length > 1">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link nav--text" (click)="closeSession()">
          <fa-icon [icon]="icons.close"></fa-icon>
          <span class="hide-name">Cerrar</span>
        </a>
      </li>
    </ng-template> -->
  </ul>

</nav>
