import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {AllyModel, AllyCustomer} from "../../models/ally/ally.model";
import {HomeModel} from "../../models/home/home.model";

@Injectable({
  providedIn: 'root'
})
export class AllyService {
  private readonly url: string;

  constructor(
    private htpp: HttpClient
  ) {
    this.url = environment.url;
  }

  getDataHome(): Observable<HomeModel> {
    const url = `${this.url}/api/mx/homepages/`;
    return this.htpp.get<HomeModel>(url)
  }

  getInfoOfAlly(brand: string): Observable<AllyModel> {
    const url = `${this.url}/api/landing_pages/${brand}/`;
    return this.htpp.get<AllyModel>(url)
  }

  getAllyCustomer(ally: string): Observable<Array<AllyCustomer>> {
    const url = `${this.url}/api/catalogs/ally_customers/?ally__code=${ally}`;
    return this.htpp.get<Array<AllyCustomer>>(url)
  }
}
