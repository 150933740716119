<div class="container">
  <div class="row">
    <div class="col">
      <h1>Blog</h1>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-lg-4 col-md-4 animate__animated animate__zoomIn" *ngFor="let blog of arrayBlog">
      <div class="card" >
        <img class="card-img-top" [src]="blog.image | safeHtml:'url'" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">{{ blog.title }}</h5>
          <p class="card-text">
            {{blog.abstract}}
          </p>
          <a href="#" [routerLink]="[blog.slug]" class="btn btn-primary">
            Ver mas
          </a>
        </div>
      </div>

    </div>
  </div>

</div>
