import { environment } from "../../environments/environment";

export const HOME_LABELS = {
  BANNER_TITLE: '¡Hoy decido hacer crecer mi negocio!',
  BANNER_DESCRIPTION: 'Enko es una plataforma en línea con cursos 100% gratuitos para impulsar negocios como el tuyo.',
  BANNER_TEXT_BUTTON: 'Regístrate',
  TITLE_COURSE: 'Con Enko aprenderás a',
  TITLE_COURSE_COPPEL: 'Con Fundación Coppel aprenderás a',
  TITLE_COURSE_OLEODUCTO: 'Con Oleoducto Vivo aprenderás a',
  TITLE_COURSE_BANORTE: 'Podrás aprender a',
  COURSE_BUSINESS: 'Dirigir mejor tu negocio',
  COURSE_MER: 'Usar la Mercadotecnia a tu favor',
  COURSE_CLIENT: 'Atraer clientes',
  COURSE_PROMO: 'Hacer promociones que vendan',
  COURSE_CRISIS: 'Enfrentar momentos de crisis',
  COURSE_COMEX_ONE: 'Capacitarte',
  COURSE_COMEX_TWO: 'Ganar más',
  COURSE_COMEX_TREE: 'Tener más clientes',
  COURSE_COMEX_FOUR: 'Administrarte mejor',
  COURSE_COMEX_FIVE: 'Regresar a la nueva normalidad',
  BUTTON_WATCH_HOW_TO_WORK: 'Ver cómo funciona'
}

export const HOME_CATEGORIES = {
  CATEGORY_MARKETING: 'Mercadotecnia',
  CATEGORY_SELLS: 'Ventas',
  CATEGORY_ADMIN: 'Administración',
  CATEGORY_FINANCE: 'Finanzas',
  CATEGORY_PERSONAL: 'Desarrollo Personal'
}

export const HOME_COMEX_CATEGORIES = {
  CATEGORY_ONE: '¿Cómo conseguir más clientes?',
  CATEGORY_TWO: 'Quiero vender más, ¿cómo le hago?',
  CATEGORY_THREE: '¿Cómo diferenciar mi negocio del resto?',
  CATEGORY_FOUR: '¿Cómo hago crecer mi negocio?',
  CATEGORY_FIVE: '¿Cómo cuidar las finazas de mi negocio?'
}

export const LESSON_TEXT = {
  NO_FAVORITES_1: 'Aún no tienes lecciones favoritas',
  NO_FAVORITES_2: 'Agrega una activando el corazón',
}

export const HOME_COMEX__CATEGORIES = [
  {
    text: HOME_COMEX_CATEGORIES.CATEGORY_ONE,
    img: null
  },
  {
    text: HOME_COMEX_CATEGORIES.CATEGORY_TWO,
    img: null
  },
  {
    text: HOME_COMEX_CATEGORIES.CATEGORY_THREE,
    img: null
  },
  {
    text: HOME_COMEX_CATEGORIES.CATEGORY_FOUR,
    img: null
  },
  {
    text: HOME_COMEX_CATEGORIES.CATEGORY_FIVE,
    img: null
  }
]

export const HOME_DEFAULT_CATEGORIES = [
  {
    text: HOME_CATEGORIES.CATEGORY_SELLS,
    img: `${environment.url_S3assets}Homepage_Images/Categories/ventas_enko.svg`
  },
  {
    text: HOME_CATEGORIES.CATEGORY_MARKETING,
    img: `${environment.url_S3assets}Homepage_Images/Categories/marketing_enko.svg`
  },
  {
    text: HOME_CATEGORIES.CATEGORY_ADMIN,
    img: `${environment.url_S3assets}Homepage_Images/Categories/administracion_enko.svg`
  },
  {
    text: HOME_CATEGORIES.CATEGORY_FINANCE,
    img: `${environment.url_S3assets}Homepage_Images/Categories/finanzas_enko.svg`
  },
  {
    text: HOME_CATEGORIES.CATEGORY_PERSONAL,
    img: `${environment.url_S3assets}Homepage_Images/Categories/desarrollo_enko.svg`
  }
]

export const HOME_COMPARTAMOS_CATEGORIES = [
  {
    text: HOME_CATEGORIES.CATEGORY_SELLS,
    img: `${environment.url_S3assets}Homepage_Images/Categories_compartamos/ventas_compartamos.svg`
  },
  {
    text: HOME_CATEGORIES.CATEGORY_MARKETING,
    img: `${environment.url_S3assets}Homepage_Images/Categories_compartamos/marketing_compartamos.svg`
  },
  {
    text: HOME_CATEGORIES.CATEGORY_ADMIN,
    img: `${environment.url_S3assets}Homepage_Images/Categories_compartamos/administracion_compartamos.svg`
  },
  {
    text: HOME_CATEGORIES.CATEGORY_FINANCE,
    img: `${environment.url_S3assets}Homepage_Images/Categories_compartamos/finanzas_compartamos.svg`
  },
  {
    text: HOME_CATEGORIES.CATEGORY_PERSONAL,
    img: `${environment.url_S3assets}Homepage_Images/Categories_compartamos/desarrollo_compartamos.svg`
  }
]

export const HOME_DEFAULT_COURSE = [
  {
    text: HOME_LABELS.COURSE_BUSINESS,
    img: `${environment.url_S3assets}Homepage_Images/Course_general/negocio_gray.svg`
  },
  {
    text: HOME_LABELS.COURSE_MER,
    img: `${environment.url_S3assets}Homepage_Images/Course_general/mercadotecnia_gray.svg`
  },
  {
    text: HOME_LABELS.COURSE_CLIENT,
    img: `${environment.url_S3assets}Homepage_Images/Course_general/clientes_gray.svg`
  },
  {
    text: HOME_LABELS.COURSE_PROMO,
    img: `${environment.url_S3assets}Homepage_Images/Course_general/promo_gray.svg`
  },
  {
    text: HOME_LABELS.COURSE_CRISIS,
    img: `${environment.url_S3assets}Homepage_Images/Course_general/crisis_gray.svg`
  }
]

export const HOME_COMEX_COURSE = [
  {
    text: HOME_LABELS.COURSE_COMEX_ONE,
    img: `${environment.url_S3assets}Homepage_Images/Course_comex/capacitarte_comex.svg`
  },
  {
    text: HOME_LABELS.COURSE_COMEX_TWO,
    img: `${environment.url_S3assets}Homepage_Images/Course_comex/ganar_comex.svg`
  },
  {
    text: HOME_LABELS.COURSE_COMEX_TREE,
    img: `${environment.url_S3assets}Homepage_Images/Course_comex/clientes_comex.svg`
  },
  {
    text: HOME_LABELS.COURSE_COMEX_FOUR,
    img: `${environment.url_S3assets}Homepage_Images/Course_comex/administrar_comex.svg`
  },
  {
    text: HOME_LABELS.COURSE_COMEX_FIVE,
    img: `${environment.url_S3assets}Homepage_Images/Course_comex/normalidad_comex.svg`
  }
]

export const HOME_COMPARTAMOS_COURSE = [
  {
    text: HOME_LABELS.COURSE_BUSINESS,
    img: `${environment.url_S3assets}Homepage_Images/Course_compartamos/negocio_compartamos.svg`
  },
  {
    text: HOME_LABELS.COURSE_MER,
    img: `${environment.url_S3assets}Homepage_Images/Course_compartamos/mercadotecnia_compartamos.svg`
  },
  {
    text: HOME_LABELS.COURSE_CLIENT,
    img: `${environment.url_S3assets}Homepage_Images/Course_compartamos/clientes_compartamos.svg`
  },
  {
    text: HOME_LABELS.COURSE_PROMO,
    img: `${environment.url_S3assets}Homepage_Images/Course_compartamos/promo_compartamos.svg`
  },
  {
    text: HOME_LABELS.COURSE_CRISIS,
    img: `${environment.url_S3assets}Homepage_Images/Course_compartamos/crisis_compartamos.svg`
  }
]

export const HOME_MOODELO_COURSE = [
  {
    text: HOME_LABELS.COURSE_BUSINESS,
    img: `${environment.url_S3assets}Homepage_Images/Course_grupomodelo/negocio_modelo.svg`
  },
  {
    text: HOME_LABELS.COURSE_MER,
    img: `${environment.url_S3assets}Homepage_Images/Course_grupomodelo/mercadotecnia_modelo.svg`
  },
  {
    text: HOME_LABELS.COURSE_CLIENT,
    img: `${environment.url_S3assets}Homepage_Images/Course_grupomodelo/clientes_modelo.svg`
  },
  {
    text: HOME_LABELS.COURSE_PROMO,
    img: `${environment.url_S3assets}Homepage_Images/Course_grupomodelo/promo_modelo.svg`
  },
  {
    text: HOME_LABELS.COURSE_CRISIS,
    img: `${environment.url_S3assets}Homepage_Images/Course_grupomodelo/crisis_modelo.svg`
  }
]

export const HOME_COPPEL_COURSE = [
  {
    text: HOME_LABELS.COURSE_BUSINESS,
    img: `${environment.url_S3assets}Homepage_Images/Course_coppel/negocio_coppel.svg`
  },
  {
    text: HOME_LABELS.COURSE_MER,
    img: `${environment.url_S3assets}Homepage_Images/Course_coppel/mercadotecnia_coppel.svg`
  },
  {
    text: HOME_LABELS.COURSE_CLIENT,
    img: `${environment.url_S3assets}Homepage_Images/Course_coppel/clientes_coppel.svg`
  },
  {
    text: HOME_LABELS.COURSE_PROMO,
    img: `${environment.url_S3assets}Homepage_Images/Course_coppel/promo_coppel.svg`
  },
  {
    text: HOME_LABELS.COURSE_CRISIS,
    img: `${environment.url_S3assets}Homepage_Images/Course_coppel/crisis_coppel.svg`
  }
]

export const HOME_OLEODUCTO_COURSE = [
  {
    text: HOME_LABELS.COURSE_BUSINESS,
    img: `${environment.url_S3assets}Homepage_Images/Course_oleoducto/negocio_oleoducto.svg`
  },
  {
    text: HOME_LABELS.COURSE_MER,
    img: `${environment.url_S3assets}Homepage_Images/Course_oleoducto/mercadotecnia_oleoducto.svg`
  },
  {
    text: HOME_LABELS.COURSE_CLIENT,
    img: `${environment.url_S3assets}Homepage_Images/Course_oleoducto/clientes_oleoducto.svg`
  },
  {
    text: HOME_LABELS.COURSE_PROMO,
    img: `${environment.url_S3assets}Homepage_Images/Course_oleoducto/promo_oleoducto.svg`
  },
  {
    text: HOME_LABELS.COURSE_CRISIS,
    img: `${environment.url_S3assets}Homepage_Images/Course_oleoducto/crisis_oleoducto.svg`
  }
  
]


