import { Component, OnInit, Input , Injectable} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbDateCustomParserFormatter} from '../../dateformat/dateformat';
import { NgbDateParserFormatter, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {NgbDateStruct, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import { environment } from "../../../../environments/environment";
import * as moment from 'moment';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
   ]
})

export class DatepickerComponent implements OnInit {
  s3UrlImage: string = `${environment.url_S3assets}`;
  minDate = {year: 2022, month: 1, day: 1};
  model: NgbDateStruct;
  @Input() parentForm: FormGroup;
  @Input() controlName: string;

  date : any;
  


  constructor() { }

  ngOnInit(): void {
    let now = moment();
    now = now.subtract(5, "years");
    const dateMin = now.format("DD-MM-YYYY");
    const dateSeparate = dateMin.split('-');
    this.minDate = {year: Number(dateSeparate[2]), month: Number(dateSeparate[1]), day: Number(dateSeparate[0])};
  }
}
