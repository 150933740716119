<div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span class="color-close" aria-hidden="true">&times;</span>
    </button>
</div>
    
<div class="modal-body">
    <div class="container text-center">
        <div class="row">
            <div class="col col-12">
                <video #playerVideo width="100%" height="auto" controls controlsList="nodownload" autoplay [src]="getVideoUrl() | safeHtml: 'resourceUrl'" type="video/mp4">
                </video>
            </div>
        </div>
    </div>
</div>