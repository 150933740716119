import { Component, OnInit, Input } from '@angular/core';
import {AllyModel} from "../../../models/ally/ally.model";

@Component({
  selector: 'app-block-extra',
  templateUrl: './block-extra.component.html',
  styleUrls: ['./block-extra.component.scss']
})
export class BlockExtraComponent implements OnInit {
  @Input() ally: AllyModel;

  constructor() { }

  ngOnInit(): void {
  }

}
