<div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span class="color-close" aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="container">
        <div class="row">
            <div class="col col-12">
                <p class="affogato-bold text-title"> ¿Cuáles de los siguientes temas te gustaría aprender más para mejorar en tu negocio? </p>
                <form [formGroup]="formSurvey">
                    <div class="row">
                        <div class="col col-md-12">
                            <div class="form-group form-check" *ngFor="let businessImprovements of arrayBusinessImprovements">
                                <input type="checkbox" class="form-check-input" id="check-{{businessImprovements.id}}" [value]="businessImprovements.id" (change)="checkOption($event.target.checked, businessImprovements)">
                                <label class="form-check-label" for="check-{{businessImprovements.id}}">{{businessImprovements.text}}</label>
                            </div>
                        </div>
                    </div>
                </form> 
            </div>
        </div>
    </div>
</div>
<div class="modal-footer text-center d-flex justify-content-between">
    <span class="omitir" (click)="closeModal()">Omitir</span>
    <button 
        class="btn btn-primary" 
        type="button"
        id="surveyButton"
        (click)="sendSurvey()"
        [disabled]="isDisabled">Guardar </button>
</div>
