<div class="container-fluid" *ngIf="!ally; else bannerBrand">
  <div class="row banner-desktop justify-content-center align-items-center align-self-center">
    <div class="col col-6">
      <h1 class="header-box-title affogato-bold">{{ banner.title }}</h1>
      <p class="header-box-subtitle">{{ banner.description }}</p>
      <div class="row button justify-content-center">
        <button class="btn btn-big btn-proxima-semibold text-center" (click)="goRegister()">Regístrate gratis</button>
      </div>
    </div>
    <div class="col col-6">
      <img [src]="banner.img" loading="lazy" width="640" height="360" alt="Enko" />
    </div>
  </div>
  <div class="row banner-mobile justify-content-center align-items-center align-self-center">
    <div class="col col-12">
      <img [src]="banner.img" alt="Enko" class="img" />
    </div>
    <div class="col col-12">
      <h1 class="header-box-title affogato-bold">{{ banner.title }}</h1>
      <p class="header-box-subtitle">{{ banner.description }}</p>
      <div class="row button justify-content-center">
        <button class="btn btn-big btn-proxima-semibold text-center" (click)="goRegister()">Regístrate gratis</button>
      </div>
    </div>
  </div>
</div>

<ng-template #bannerBrand>
  <div class="container-fluid">
    <div class="row banner-ally-desktop justify-content-center align-items-center align-self-center">
      <div class="col col-6 header-box">
        <h1
          class="header-box-title affogato-bold"
          *ngIf="brand == 'mipymedigital'; else elseBlock"
          style="font-family: 'Baloo 2', extrabold">
          {{ ally?.title }}
        </h1>
        <ng-template #elseBlock>
          <h1
            class="{{
              landingTemplate == 'coppel' ? 'header-box-title-coppel' : 'header-box-title'
            }} affogato-bold baloo-cursive"
            *ngIf="ally.code !== 'compartamosbanco'">
            {{ ally?.title }}
          </h1>
        </ng-template>
        <h1 class="header-box-title affogato-bold" *ngIf="ally.code === 'compartamosbanco'">
          Estás a punto de cambiar el rumbo
          <h1 class="bold-text">de tu negocio.</h1>
        </h1>
        <p class="header-box-subtitle-mipymedigital" *ngIf="brand == 'mipymedigital'; else elseBlockText">
          {{ ally?.text }}
        </p>
        <ng-template #elseBlockText>
          <p class="{{ ally.code == 'woccu' ? 'header-box-subtitle-woccu' : 'header-box-subtitle' }}">
            {{ ally?.text }}
          </p>
        </ng-template>
        <div class="row button justify-content-center">
          <button class="btn btn-big btn-proxima-semibold text-center" (click)="goRegister()">Regístrate gratis</button>
        </div>
      </div>
      <div class="col col-6">
        <img [src]="ally.header_image" loading="lazy" alt="Enko" class="img" />
      </div>
    </div>

    <div class="row banner-ally-mobile justify-content-center align-items-center align-self-center">
      <div class="col col-12">
        <img [src]="ally.header_image" alt="Enko" class="img" />
      </div>
      <div class="col col-12 header-box">
        <h1
          class="{{ landingTemplate == 'coppel' ? 'header-box-title-coppel' : 'header-box-title' }} affogato-bold"
          *ngIf="ally.code !== 'compartamosbanco'">
          {{ ally?.title }}
        </h1>
        <h1 class="header-box-title affogato-bold" *ngIf="ally.code === 'compartamosbanco'">
          Estás a punto de cambiar el rumbo
          <h1 class="bold-text">de tu negocio.</h1>
        </h1>
        <p class="{{ ally.code == 'woccu' ? 'header-box-subtitle-woccu' : 'header-box-subtitle' }}">{{ ally?.text }}</p>
        <div class="row button justify-content-center">
          <button class="btn btn-big btn-proxima-semibold text-center" (click)="goRegister()">Regístrate gratis</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
