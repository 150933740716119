import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LessonFavoriteModel, LessonModel, LessonRecommendationModel} from "../../../models/lesson/lesson.model";
import {ActivatedRoute, Router} from "@angular/router";
import {LessonService, ModuleService, SweetAlertService} from "../../../services";
import {ICON} from "../../../utils/icons";
import {faHeart, faStarHalfAlt, faStar, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import { TypeSweet } from 'src/app/utils/typeSweet';
import { ModuleModel } from 'src/app/models/module/module.model';

@Component({
  selector: 'app-card-lesson',
  templateUrl: './card-lesson.component.html',
  styleUrls: ['./card-lesson.component.scss']
})
export class CardLessonComponent implements OnInit {

  @Input() lesson: LessonModel;
  @Input() finish: boolean;
  @Input() explore: boolean;
  @Input() module: boolean;
  @Input() unblocked:boolean = true;
  @Input() moduleData:ModuleModel;
  @Output() favorite = new EventEmitter<LessonFavoriteModel>();

  icon: { fav: any, fav_solid?: any } = {
    fav: ICON.FAVORITE,
    fav_solid: faHeart
  }

  iconStar: { fav: any, fav_solid?: any, fav_half?: any } = {
    fav: ICON.STAR,
    fav_solid: faStar,
    fav_half: faStarHalfAlt
  }

  iconUser = faUserCircle

  constructor(
    private _routerActivate: ActivatedRoute,
    private _router: Router,
    private _modulesService: ModuleService,
    private _lessonService: LessonService,
    private sweetAlert: SweetAlertService
  ) { }

  ngOnInit(): void {
  }

  navigateToDetail(item: LessonRecommendationModel) {
    if (this.unblocked == false) {
      this._modulesService.getModuleId(this.moduleData.depends_on)
      .subscribe(res => {
        this.sweetAlert.basicSweetNotClose(res.name, 'Deberás completar el módulo anterior para continuar con tu capacitación.', TypeSweet.info);
      });
      return;
    }
    if (item.introduction) {
      if(this.finish){
        this._router.navigate(['../../' + item.slug + '/' + item.introduction], {relativeTo: this._routerActivate})
      }else if(this.explore){
        this._router.navigate(['../seccion/' + item.slug + '/' + item.introduction], {relativeTo: this._routerActivate})

      }else if(this.module){
        this._router.navigate(['../../' + item.slug + '/' + item.introduction], {relativeTo: this._routerActivate})
      }else{
        this._router.navigate(['../' + item.slug + '/' + item.introduction], {relativeTo: this._routerActivate})
      }
    } else {
      this._router.navigate(['../' + item.slug + '/parte/' + item.current_part], {relativeTo: this._routerActivate})
    }
  }

  favoriteLesson(lesson: LessonRecommendationModel) {
    this._lessonService.lessonFavorite(lesson.slug)
      .subscribe(res => {
        lesson.favorite = res.is_favorite;
        this.favorite.emit(res);
      })
  }
}
