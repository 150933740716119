import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { environment } from "../../../../environments/environment";
import { AllyModel } from "../../../models/ally/ally.model";

@Component({
  selector: 'app-footer-auth',
  templateUrl: './footer-auth.component.html',
  styleUrls: ['./footer-auth.component.scss']
})
export class FooterAuthComponent implements OnInit {
  @Input() allyDetail:AllyModel;
  s3UrlImage: string = `${environment.url_S3assets}`;

  constructor(){}

  ngOnChanges(changes: SimpleChanges): void {
    this.allyDetail = changes.allyDetail.currentValue
  }

  ngOnInit(): void {
  }

}
