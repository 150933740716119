import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { environment } from "../../../../environments/environment";
import { AllyModel } from "../../../models/ally/ally.model";

@Component({
  selector: 'app-banner-carousel',
  templateUrl: './banner-carousel.component.html',
  styleUrls: ['./banner-carousel.component.scss']
})

export class BannerCarouselComponent implements OnInit {
  @Input() allyDetail:AllyModel;
  assetsUrl:string;
  arrayImage:Array<any> = [];
  arrayImageMobile:Array<any> = [];
  constructor() {
    this.assetsUrl = environment.url_S3assets;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.allyDetail = changes.allyDetail.currentValue
  }

  ngOnInit(): void {
    this.arrayImage = [
      {image: `${this.assetsUrl}Homepage_Login_Image/Carousel_1.jpg`},
      {image: `${this.assetsUrl}Homepage_Login_Image/Carousel_2.jpg`},
      {image: `${this.assetsUrl}Homepage_Login_Image/Carousel_3.jpg`}
    ]
    this.arrayImageMobile = [
      {image: `${this.assetsUrl}Homepage_Login_Image/Carousel_M_1.jpg`},
      {image: `${this.assetsUrl}Homepage_Login_Image/Carousel_M_2.jpg`},
      {image: `${this.assetsUrl}Homepage_Login_Image/Carousel_M_3.jpg`}
    ]
  }
}
