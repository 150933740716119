import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie';
import { Prize } from 'src/app/models/achievements/achievements.model';
import { AchievementsService, ProfileService, SweetAlertService } from 'src/app/services';
import { ShopService } from 'src/app/services/shop/shop.service';
import { DATA_LOCALSTORAGE } from 'src/app/utils/dataLocalstorage';
import { TypeSweet } from 'src/app/utils/typeSweet';
import Swal from 'sweetalert2';
/* Components */
import {ModalAchievementComponent} from '../../../shared/components/modal-achievement/modal-achievement.component';

@Component({
  selector: 'app-list-achievements',
  templateUrl: './list-achievements.component.html',
  styleUrls: ['./list-achievements.component.scss']
})
export class ListAchievementsComponent implements OnInit {
  @Input() achievements;
  @Input() brand;
  shopMode:string;
  modalOption: NgbModalOptions = {};
  disabledBtn: boolean = false;
  constructor(
    private modalService: NgbModal,
    private _profileService: ProfileService,
    private _cookieService: CookieService,
    private _achievementService: AchievementsService,
    private _shopService: ShopService,
    private sweetService: SweetAlertService,
  ) { 
    this.loadPersonalData();
  }

  ngOnInit(): void {
    this.shopMode = this._cookieService.get(DATA_LOCALSTORAGE.SHOP_MODE);
  }

  modalAchievement(idAchievement){
    if (this.shopMode === 'true') this.loadPrize(idAchievement);
    else {
      this.modalOption.backdrop = 'static';
      this.modalOption.size = 'lg';
      this.modalOption.scrollable = true;
      this.modalOption.centered = true;
      const modalRef = this.modalService.open(ModalAchievementComponent, this.modalOption);
      modalRef.componentInstance.idAchievement = idAchievement;
      modalRef.componentInstance.isAcelera = false;
      modalRef.componentInstance.isVideo = false;
      modalRef.componentInstance.isCertificate = false;
      modalRef.result.then(
        () => {
          /* console.log("Data"); */
        },
        ()=> {
          /* console.log("Close icon clicked or backdrop clicked"); */
      });   
    } 
  }

  loadPrize(idAchievement): void{
    this._achievementService.getPrize(idAchievement)
     .subscribe( (res: Prize) => {
      this._shopService.updateComponent();
       this.modalOption.backdrop = 'static';
      this.modalOption.size = 'lg';
      this.modalOption.scrollable = true;
      this.modalOption.centered = true;
      let isAcelera = false;
      let isVideo = false;
      let isCertificate = false;
      if (res.prize_category == "acelera") isAcelera = true;
      if (res.prize_category == "video") isVideo = true;
      if (res.prize_category == "certificate") isCertificate = true;
      const modalRef = this.modalService.open(ModalAchievementComponent, this.modalOption);
      modalRef.componentInstance.idAchievement = idAchievement;
      modalRef.componentInstance.isAcelera = isAcelera;
      modalRef.componentInstance.isVideo = isVideo;
      modalRef.componentInstance.isCertificate = isCertificate;
      modalRef.result.then(
        () => {
          /* console.log("Data"); */
        },
        ()=> {
          /* console.log("Close icon clicked or backdrop clicked"); */
      });  
     },(error:any)=>{
       if(error.status == 404 || error.status == 400){
         console.log(error);
        this.sweetService.basicSweetNotClose('Logro Bloqueado', `${ error.error.detail }`, TypeSweet.error);
      }
      if(error.status == 500){
        this.sweetService.basicSweetNotClose('Error', `Error Interno de Servidor (500)`, TypeSweet.error);
      }
      if(error.status == 400 && error.error.user_profile_completed == false){
        Swal.fire({
          title: `Espera`,
          text: error.error.detail,
          showCancelButton: false,
          confirmButtonColor: '#215aa8',
          cancelButtonColor: '#215aa8',
          confirmButtonText: 'Completar perfil',
        }).then(function(){
          const currentUrl = window.location.href.replace('seccion/logros', 'seccion/perfil');
          window.location.replace(currentUrl);
          //this.route.navigate([`/${this._cookieService.get(DATA_LOCALSTORAGE.COUNTRY)}/${this._cookieService.get(DATA_LOCALSTORAGE.CODE_ALLY)}/seccion/perfil`]);
        });
      }
     }
     )
  }

  loadPersonalData(){
    this._profileService.getPersonalData().subscribe(
      res =>{
        for (const property in res) {
          if (res[property] == null && property != 'picture' && property != 'achievement') this.disabledBtn = true;
          console.log(`${res[property]}`);
        }
        
      }
    )
  }

}
