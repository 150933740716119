<nav id="bar" class="navbar navbar-expand-sm navbar-dark nav__bg sticky-top justify-content-center">
  <a
    class="navbar-brand"
    [routerLink]="['inicio']"
    *ngIf="!allyDetail || (allyDetail && allyDetail.navbar_enko_logo_enabled)">
    <img src="{{ s3UrlImage }}Logos/logo-enko-white.png" loading="lazy" class="logo-enko" alt="Logo Enko" />
  </a>
  <a
    class="{{ landingTemplate == 'coppel' ? 'navbar-brand-coppel' : 'navbar-brand-ally' }}"
    [routerLink]="['inicio']"
    *ngIf="allyDetail">
    <img [src]="allyDetail.logo" loading="lazy" class="logo-ally" alt="Enko" />
  </a>

  <ul class="navbar-nav justify-mobile-enko">
    <li class="nav-item" routerLinkActive="active" *ngFor="let item of menuOptionsEnko" style="width: max-content">
      <a
        class="nav-link nav-enko-landing nav--text"
        href="https://empresas.enko.org/"
        target="_blank"
        *ngIf="brand == 'enko'">
        <fa-icon [icon]="item.icon"></fa-icon>
        {{ item.name }}</a
      >
    </li>
    <li class="nav-item" routerLinkActive="active" *ngFor="let item of menuOptions" style="width: max-content">
      <a
        class="{{
          allyDetail && landingTemplate == 'coppel' ? 'nav-link-coppel' : 'nav-link'
        }} nav--text nav-enko-landing"
        [routerLink]="[item.link]">
        <fa-icon [icon]="item.icon"></fa-icon>
        {{ item.name }}</a
      >
    </li>
  </ul>
</nav>
