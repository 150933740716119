import {Component, Input, OnInit} from '@angular/core';
import {OwlOptions} from "ngx-owl-carousel-o";
import { ModuleModel } from 'src/app/models/module/module.model';
import {LessonRecommendationModel} from "../../../models/lesson/lesson.model";

@Component({
  selector: 'app-slider-lessons',
  templateUrl: './slider-lessons.component.html',
  styleUrls: ['./slider-lessons.component.scss']
})
export class SliderLessonsComponent implements OnInit {

  @Input() arrayLessons: Array<LessonRecommendationModel>;
  @Input() explore:boolean;
  @Input() unblocked:boolean = true;
  @Input() moduleData:ModuleModel;
  customOptions: OwlOptions = {
    loop: true,
    autoWidth: true,
    autoplay: false,
    autoplayHoverPause: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    mergeFit: false,
    navText: ["<", ">"],
    stagePadding: 50,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 4
      },
    },
    nav: true
  }
  constructor(
  ) { }

  ngOnInit(): void { 
  }

}
