import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor(
  ) { }
  
  basicSweet(title: string, message: string, icon: any) {
    return Swal.fire({
      title: `${title}`,
      icon,
      text: `${message}`,
      showConfirmButton: false,
      heightAuto: false,
      allowOutsideClick: false,
      timer: 1500
    });
  }

  basicSweetNotClose(title: string, message: string, icon: any) {
    return Swal.fire({
      title: `${title}`,
      icon,
      text: `${message}`,
      showConfirmButton: true,
      heightAuto: false,
      allowOutsideClick: true,
    });
  }

  askSweetAlertAcelera(title: string, text: string, icon: any){
    return Swal.fire({
      title: `${title}`,
      text: `${text}`,
      icon,
      showCancelButton: true,
      confirmButtonColor: '#215aa8',
      cancelButtonColor: '#215aa8',
      cancelButtonText: 'Modificar dirección',
      confirmButtonText: 'Continuar',
    })
  }
}
