<div class="container-fluid">
  <div *ngIf="landingTemplate !== 'coppel'" class="row justify-content-center">
    <div class="col-lg-3 text-center">
      <img src="{{ s3UrlImage }}Logos/logo-enko-gray.png" alt="Enko" class="logo" />
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-lg-12 col-12 text-center">
      <a
        class="footer-icon facebook"
        href="https://www.facebook.com/fundacioncoppel"
        target="_blank"
        *ngIf="landingTemplate == 'coppel'; else elseBlockFacebook">
        <fa-icon [icon]="icons.facebook"></fa-icon>
      </a>
      <ng-template #elseBlockFacebook>
        <a class="footer-icon facebook" href="https://www.facebook.com/enko.org/" target="_blank">
          <fa-icon [icon]="icons.facebook"></fa-icon>
        </a>
      </ng-template>
      <a
        class="footer-icon youtube"
        href="mailto:coppel.emprende@coppel.com"
        target="_blank"
        *ngIf="landingTemplate == 'coppel'; else elseBlockYoutube">
        <fa-icon [icon]="icons.mail"></fa-icon>
      </a>
      <ng-template #elseBlockYoutube>
        <a
          class="footer-icon youtube"
          href="https://www.youtube.com/channel/UCTbC6jOEt-3il0ecZaycOcg/videos"
          target="_blank">
          <fa-icon [icon]="icons.youtube"></fa-icon>
        </a>
      </ng-template>
      <a
        class="footer-icon"
        href="https://www.fundacioncoppel.org/"
        target="_blank"
        *ngIf="landingTemplate == 'coppel'; else elseBlockInstagram">
        <!--<fa-icon [icon]="icons.instagram"></fa-icon> -->
        <fa-icon [icon]="icons.web"></fa-icon>
      </a>
      <ng-template #elseBlockInstagram>
        <a class="footer-icon" href="https://www.instagram.com/enko_org/" target="_blank">
          <img class="icon-instagram" src="assets/icons/instagram.svg" />
        </a>
      </ng-template>
      <a class="link" [routerLink]="['terminos-y-condiciones']">Términos y Condiciones</a>
      <a class="link" [routerLink]="['aviso-de-privacidad']">Aviso de Privacidad</a>
      <p *ngIf="landingTemplate !== 'coppel'" class="duty">2020 Enko Solutions. Todos los derechos reservados.</p>
    </div>
  </div>
</div>

<div class="container-fluid footer">
  <div class="row justify-content-center">
    <div
      class="col-lg-3 col-md-4 col text-center"
      *ngIf="!allyDetail || (allyDetail && allyDetail.navbar_enko_logo_enabled)">
      <p class="footer-text affogato-medium">
        Con el apoyo de
        <img class="logo-visa" src="{{ s3UrlImage }}Logos/logo-visa-white.svg" loading="lazy" alt="Visa" />
      </p>
    </div>
    <div class="col col-12 col-md-8 text-center" *ngIf="allyDetail && !allyDetail.navbar_enko_logo_enabled">
      <p class="footer-text">
        <img [src]="allyDetail.footer_logo" loading="lazy" class="logo-ally" alt="Enko" />
      </p>
    </div>
  </div>
</div>
