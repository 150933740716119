<div class="card card-border card-detail-part" *ngIf="!nextLesson">
    <div class="card-body" *ngIf="rating">
        <h5 class="card-title text-center questions-title">¿Recomendarías esta lección a un conocido, amigo o familiar?</h5>
        <div class="row text-center range">
            <div class="col col-12">
                <div class="range-buttons">
                    <button *ngFor="let range of [1,2,3,4,5]" type="button" class="btn"
                        (click)="setRange(range)">{{range}}</button>
                </div>
                <div class="range-text">
                    <span>Poco probable</span>
                    <span>Muy probable</span>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body" *ngIf="questions">
        <h5 class="card-title text-center questions-title">¿Qué te gustaria que mejoremos en esta lección?</h5>
        <ul class="list-group list-group-flush list-group-mine">
            <li *ngFor="let review of reviewArray" class="list-group-item" (click)="addRatingLesson(review.id)">
                {{review.text}}</li>
        </ul>
        <div class="text-center">
            <span (click)="omitir()" class="card-link">Omitir</span>
        </div>
    </div>
</div>
<div class="container" *ngIf="nextLesson">
    <div class="row">
        <div class="col col-12 questions-title text-center">Te recomendamos las siguientes lecciones</div>
        <div class="col col-12 col-md-6" *ngFor="let lesson of lessonArray">
            <app-card-lesson [lesson]="lesson" [finish]="finish"></app-card-lesson>
        </div>
    </div>
</div>
<div class="row text-center buttons">
    <div class="col col-4">
        <a [routerLink]="['../' + detailLesson?.introduction]" class="tetxt link">Inicio de la lección</a>
    </div>
    <div class="col col-4">
        <share-button 
            theme="material-dark" 
            button="facebook" 
            text="Compartir" 
            [showText]="true" 
            [url]="getUrl()">
        </share-button>
    </div>
    <div class="col col-4">
        <a [routerLink]="[hasNextLessonUrl]" class="btn" *ngIf="hasNextLesson; else elseBlock">
            Siguiente lección
        </a>
        <ng-template #elseBlock>
            <a [routerLink]="['../../lecciones']" class="btn">Ir a lecciones</a>
        </ng-template>
    </div>
</div>