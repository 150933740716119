import { Component, OnInit, Input } from '@angular/core';
import {ICON} from "../../../utils/icons";
@Component({
  selector: 'app-files-part',
  templateUrl: './files-part.component.html',
  styleUrls: ['./files-part.component.scss']
})
export class FilesPartComponent implements OnInit {
  @Input() files;
  icon: { download: any,  } = {
    download: ICON.DOWNLOAD,
  }
  constructor() { }

  ngOnInit(): void {
  }

}
