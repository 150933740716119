import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {PaginatorLessonFavorite, PaginatorPartsLesson} from "../../models/paginator/paginator.model";
import {map} from "rxjs/operators";
import {PartModel, PartWelcome} from "../../models/lesson/part.model";
import {LessonFavoriteModel, LessonDetailModel, LessonRecommendationModel, LessonModel, LessonIdModel} from "../../models/lesson/lesson.model";
import {ReviewLessonModel} from "../../models/review-lesson/reviewLesson.model";
import { ModuleModel } from 'src/app/models/module/module.model';

@Injectable({
  providedIn: 'root'
})
export class LessonService {

  private readonly url: string;

  constructor(
    private http: HttpClient,
  ) {
    this.url = environment.url;
  }

  getLessonRecommendation(): Observable<Array<LessonRecommendationModel>> {
    const url = `${this.url}/api/lessons/recommendations/`;
    return this.http.get<Array<LessonRecommendationModel>>(url)
  }

  getLessonFollowing(): Observable<Array<LessonRecommendationModel>> {
    const url = `${this.url}/api/lessons/following/`;
    return this.http.get<Array<LessonRecommendationModel>>(url)
  }

  getDetailLesson(slug: string): Observable<LessonDetailModel> {
    const url = `${this.url}/api/lessons/${slug}/`;
    return this.http.get<LessonDetailModel>(url)
  }

  getPartsOfLesson(lesson: string): Observable<Array<PartModel>> {
    const url = `${this.url}/api/lessons/${lesson}/parts/`;
    return this.http.get<PaginatorPartsLesson>(url)
      .pipe(
        map(response => response.results)
      )
  }

  getDetailPartOfLesson(id: string): Observable<PartModel> {
    const url = `${this.url}/api/parts/${id}/`;
    return this.http.get<PartModel>(url)
  }

  getDetailIntroduction(id: number): Observable<PartWelcome> {
    const url = `${this.url}/api/introductions/${id}/`;
    return this.http.get<PartWelcome>(url)
  }

  getDetailIntroductionExplore(id: string): Observable<PartWelcome> {
    const url = `${this.url}/api/explore_introductions/${id}/`;
    return this.http.get<PartWelcome>(url)
  }

  lessonFavorite(lesson: string): Observable<LessonFavoriteModel> {
    const url = `${this.url}/api/lessons/${lesson}/set_favorite/`;
    return this.http.post<LessonFavoriteModel>(url, {})
  }

  getFavoriteLessons(): Observable<PaginatorLessonFavorite> {
    const url = `${this.url}/api/favorites/`;
    return this.http.get<PaginatorLessonFavorite>(url);
  }
  
  AddRatingLesson(slugLesson, ratingData){
    const url = `${this.url}/api/lessons/${slugLesson}/set_rating/`;
    return this.http.post<ReviewLessonModel>(url, ratingData)
  }

  /**
   * Metodo que consulta la lecciones siguientes al termino de una leccion
  */
  getNextLesson(lesson:string): Observable<Array<LessonModel>> {
    const url = `${this.url}/api/lessons/${lesson}/next_lessons/`;
    return this.http.get<PaginatorLessonFavorite>(url)
    .pipe(
      map(response => response.results)
    )
  }

  /**
   * Metodos que consulta lecciones en seccion de explorar
  */
  getLessonExplore(country, ally): Observable<Array<LessonRecommendationModel>> {
    const url = `${this.url}/api/new_lessons/?country=${country}&ally_code=${ally}`;
    return this.http.get<Array<LessonRecommendationModel>>(url)
  }
  getLessonMostView(country, ally): Observable<Array<LessonRecommendationModel>> {
    const url = `${this.url}/api/most_viewed_lessons/?country=${country}&ally_code=${ally}`;
    return this.http.get<Array<LessonRecommendationModel>>(url)
  }
 
  getLessonsId(id: number): Observable<PaginatorLessonFavorite>{
    const url = `${this.url}/api/lesson_modules/${id}/lessons/`;
    return this.http.get<PaginatorLessonFavorite>(url)
     
  }

  getHasNextLesson(slug: string): Observable<LessonDetailModel> {
    const url = `${this.url}/api/lessons/${slug}/has_next_lesson/`;
    return this.http.get<LessonDetailModel>(url)
  }
 
}

