<div class="container content-files">
    <div class="row text-center">
        <div class="col col-12">
            <h5 class="affogato-bold title-text">Archivos descargables</h5>
        </div>
    </div>

    <div class="row">
        <div class="col offset-2 col-8">
            <hr class="line">            
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-lg-6 col-md-6 col">
            <div class="row justify-content-center">
                <div class="col col-lg-4 col-md-6 col-6" *ngFor="let file of files">
                    <a class="title-file" [href]=file.file target="_blank">
                        <div class="row">
                            <div class="col col-12 text-center">
                                <fa-icon class="download-icon" [icon]="icon.download"></fa-icon>
                            </div>
                            <div class="col col-12 text-center">
                                <p>{{file.title}}</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>   
        </div>
    </div>
</div>
