import { Component, OnInit, Input, PLATFORM_ID, ViewChild, NgZone, ElementRef, Inject, AfterViewInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TypeSweet } from '../../../utils/typeSweet';
import { NgxSpinnerService } from "ngx-spinner";
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import {DATA_LOCALSTORAGE} from "../../../utils/dataLocalstorage";
import {isPlatformBrowser} from "@angular/common";
/* Services */
import { AchievementsService, ModuleService, StorageService, SweetAlertService} from '../../../services/index';
/* Models */
import { AchievementIdModel, AllyBusinessTypes, Prize } from 'src/app/models/achievements/achievements.model';
import { ModuleModel } from 'src/app/models/module/module.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { element } from 'protractor';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-modal-achievement',
  templateUrl: './modal-achievement.component.html',
  styleUrls: ['./modal-achievement.component.scss'],
})

export class ModalAchievementComponent implements OnInit, AfterViewInit {
  @ViewChild('agmSearch', {static: false}) agmSearch: ElementRef;
  @Input() idAchievement;
  @Input() isAcelera;
  @Input() isVideo;
  @Input() isCertificate;
  dataAchievement: AchievementIdModel;
  arrayModules: Array<ModuleModel> = [];
  aceleraForm: FormGroup;
  
  /* Variables para google maps */
  latitude: number;
  longitude: number;
  zoom:number;
  address: string;
  private geoCoder;
  
  /* Variables de logro acelera */
  urlPDF:string;
  bussinesTypeAcelera:string;
  nameBussinesAcelera: string;
  disableForm: boolean = false;
  disableButton: boolean = false;
  arrayAchievementsUserData: Array<AllyBusinessTypes> = [];

  shopMode:string;
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public activeModal: NgbActiveModal,
    private _achievementService: AchievementsService,
    private _modulesService: ModuleService,
    private _router: Router,
    private _storage: StorageService,
    private mapsAPILoader: MapsAPILoader,
    private sweetService: SweetAlertService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private ngZone: NgZone,
    private _cookieService: CookieService
  ) {
  }

  ngOnInit(): void {
    this.shopMode = this._cookieService.get(DATA_LOCALSTORAGE.SHOP_MODE);
    this.getAchievementId(this.idAchievement);
    this.loadModules();
    this.formAcelera();
  }

  formAcelera(){
    this.aceleraForm = this.fb.group({
      business_name: new FormControl(null, [Validators.required]),
      address: new FormControl(null, [Validators.required]),
      ally_business_type: new FormControl(null, [Validators.required]),
      //business_subtype: new FormControl(null, [Validators.required]),
      lat: new FormControl(null, [Validators.required]),
      long: new FormControl(null, [Validators.required]),
      url: new FormControl(null, [Validators.required]),
    })
  }
  
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    setTimeout(() => {
      this.loadMap()
    }, 1000);
  }

  loadMap(){
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      if(this.aceleraForm.get('lat').value && this.aceleraForm.get('long').value){
        this.latitude = Number(this.aceleraForm.get('lat').value);
        this.longitude = Number(this.aceleraForm.get('long').value);
      }else{
        this.latitude =  19.4326018;
        this.longitude = -99.1353936;
      }
      this.zoom = 12;

      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.agmSearch.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  loadModules(){
    this._modulesService.getModulesApi()
     .subscribe(res => {
       this.arrayModules = res;
     })
  }

  closeModal(){
    this.activeModal.dismiss()
  }

  getAchievementId(idAchievement){
    this._achievementService.getAchievementId(idAchievement)
    .subscribe(res => {
      console.log('DATA: ', res);
      this.dataAchievement = res;
      if(this.dataAchievement.module){
        this.loadAchievementsUserData(this.dataAchievement.module);
      }
      if(this.isAcelera){
        this.loadAchievementsUserData(37);
      }
      this.arrayModules.forEach(element => {
        if(this.dataAchievement.module){
          if(element.id == this.dataAchievement.module){
            this.dataAchievement.module_name = element.name
          }
        }
      });
      if(this.dataAchievement.achievement_user_data){
        this.disableForm = true;
        this.disableButton = true;
        this.aceleraForm.addControl('id', new FormControl(null,[]));
        this.aceleraForm.addControl('achievement', new FormControl(null,[]));
        this.aceleraForm.addControl('user', new FormControl(null,[]));
        this.aceleraForm.setValue(this.dataAchievement.achievement_user_data)
        this.urlPDF = this.dataAchievement.achievement_user_data.url;
      }
    })   
   
  }

  goProfile(){
    this.activeModal.close();
    this._router.navigate([`/${this._storage.get(DATA_LOCALSTORAGE.COUNTRY)}/${this._storage.get(DATA_LOCALSTORAGE.CODE_ALLY)}/seccion/perfil`]);
  }

  goModule(moduleId){
    this.activeModal.close();
    this._router.navigate([`/${this._storage.get(DATA_LOCALSTORAGE.COUNTRY)}/${this._storage.get(DATA_LOCALSTORAGE.CODE_ALLY)}/seccion/lecciones/${moduleId}`]);
  }

  // Get Current Location Coordinates
  /* setCurrentLocation() {
    if(isPlatformBrowser(this.platformId)){
      this.latitude = 19.4326018;
      this.longitude = -99.1353936;
      this.zoom = 12;
    }
  } */

  markerDragEnd($event: google.maps.MouseEvent) {
    console.log($event);
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  selectBussinesType(bussinesTypeId: any): void{
    if(bussinesTypeId){
      let bussines = this.arrayAchievementsUserData.find(element=>element.id == bussinesTypeId)
      this.bussinesTypeAcelera = bussines.name.replace(/ /g, '%20');
    }
    
  }
  
  getPDF(latitude, longitude){ 
    if(!this.aceleraForm.get('business_name')?.value){
      this.sweetService.basicSweet('Campos obligatorios', `Por favor ingresa el Nombre del Negocio`, TypeSweet.error);
      return;
    }else{
      let name = this.aceleraForm.get('business_name')?.value;
      this.nameBussinesAcelera = name.replace(/ /g,'%20')
    }
    if(!this.aceleraForm.get('ally_business_type')?.value){
      this.sweetService.basicSweet('Campos obligatorios', `Por favor selecciona el Giro de Negocio`, TypeSweet.error);
      return;
    }
    
    this.sweetService.askSweetAlertAcelera('¿Estás seguro de que la Ubicación que ingresaste es la de tu negocio?', 'Una vez dando click en "Continuar" no podrás cambiar la dirección.', 'question').then((result) => {
      if (result.value) {
        this.spinner.show();
        this._achievementService.getPDFAcelera(latitude, longitude, this.nameBussinesAcelera, this.bussinesTypeAcelera).subscribe(
          (res:any)=>{
            if(res.status == 401){
              this.sweetService.basicSweet('Error', `${ res.response }`, TypeSweet.error);
            }
            if(res.status == 402){
              this.sweetService.basicSweet('Error', `${ res.response }`, TypeSweet.error);
            }
            if(res.status == 200){
              this.aceleraForm.get('url').setValue(res.response);
              this.saveDataPDF()
            }
            this.spinner.hide();
          },(error:any)=>{
            console.log(error)
            this.spinner.hide();
            this.sweetService.basicSweet('Error', 'ocurrio un error, intenta de nuevo', TypeSweet.error);
          }
        )
      }
    }) 
  }
    
  saveDataPDF(){
    this._achievementService.addAchivementUseData(this.dataAchievement.id, this.aceleraForm.value).subscribe(
      (res:any)=>{
        this.urlPDF = res.url;
        this.disableButton = true;
        this.sweetService.basicSweet('Registro Exitoso', 'Ahora puedes abrir tu recompensa', TypeSweet.success);
      },error=>{
        this.sweetService.basicSweet('Error', `Ocurrio un error, ${error.error}`, TypeSweet.error);
        console.log(error)
      }
    )
      
  }

  /*Método que permite obtener el premio del logro*/
  loadPrize(idAchievement): void{
    this._achievementService.getPrize(idAchievement)
     .subscribe( (res: Prize) => {
       window.open(res.pdf, '_blank');
     },(error:any)=>{
       if(error.status == 404){
        this.sweetService.basicSweet('Error', `${ error.error.detail }`, TypeSweet.error);
      }
      if(error.status == 500){
        this.sweetService.basicSweet('Error', `Error Interno de Servidor (500)`, TypeSweet.error);
      }
     }
     )
  }


  loadAchievementsUserData(idModule: number): void{
    this._achievementService.getAchievementsUserData(idModule).subscribe(
      (res: Array<AllyBusinessTypes>)=>{
        this.arrayAchievementsUserData = res;
      }, error=>{
      }
    )
  }

  
  loadCerticate(certificate: string): void{
    window.open(certificate, '_blank');
  }
  
}
