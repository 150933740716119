import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthFireService {

  user: Observable<firebase.User>;

  constructor(
    private firebaseAuth: AngularFireAuth
  ) {
    this.user = firebaseAuth.authState;
  }

  /**
   * Metodo que inicializa la app de firebase
   */
  initFirebase() {
    if (firebase.apps.length === 0) {
      firebase.initializeApp(environment.firebase)
    }
  }

  /**
   * Metodo que muestra el pop up para logearse en facebook
   */
  loginByFacebook() {
    return new Promise((res, rej) => {
      this.firebaseAuth
        .signInWithPopup(new firebase.auth.FacebookAuthProvider())
        .then(value => {
          console.log('Facebook', value);
          res(value)
        })
        .catch(err => {
          console.log('Something went wrong:', err.message);
          rej(err)
        });
    })
  }

  /**
   * Metodo que se encarga de validar el numero telefonico
   * @param phone
   * @param code
   */
  singInWithPhone(phone: string, recaptcha: any): Promise<any> {
    return new Promise((res, rej) => {
      this.firebaseAuth
        .signInWithPhoneNumber(phone, recaptcha)
        .then(response => {
          res(response)
        })
        .catch(err => {
          rej(err);
        })
    })
  }

  /**
   * Metodo que cierra la sesion de la instancia de firebase
   */
  logout() {
    this.firebaseAuth
      .signOut()
      .then(
        () => console.log('Cerrado')
      )
      .catch(
        err => {
          console.log(err)
        }
      )

  }
}
