import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { DATA_LOCALSTORAGE } from '../../../utils/dataLocalstorage';
import { AllyService } from '../../../services';
import { ICON } from '../../../utils/icons';
import { CookieService } from 'ngx-cookie';
import { AllyModel } from '../../../models/ally/ally.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  s3UrlImage: string = `${environment.url_S3assets}`;
  icons: { blog: any; explore: any; login: any } = {
    blog: ICON.NEWSPAPER,
    explore: ICON.DESKTOP,
    login: ICON.USER
  };

  menuOptions: Array<{ link: string; icon: any; name: string }> = [
    /* botón de explorat descomentar si se requieres{
      link: './explorar',
      icon: ICON.DESKTOP,
      name: 'Explorar'
    }, */
    {
      link: './login',
      icon: ICON.USER,
      name: 'Entrar'
    }
  ];

  menuOptionsEnko: Array<{ link: string; icon: any; name: string }> = [
    {
      link: './login',
      icon: ICON.BRIEFCASE,
      name: 'ENKO corporativos'
    }
  ];

  allyDetail: AllyModel;
  brand: string;
  country: string;
  landingTemplate: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private route: ActivatedRoute,
    private router: Router,
    private allyService: AllyService,
    private _cookieService: CookieService
  ) {
    this.route.paramMap.subscribe((params) => {
      this.country = params.get('country');
      this.brand = params.get('brand');
      this._cookieService.put(DATA_LOCALSTORAGE.COUNTRY, this.country);
      this._cookieService.put(DATA_LOCALSTORAGE.CODE_ALLY, this.brand);
    });
  }

  ngOnInit(): void {
    this.loadDataAlly(this.brand);
  }

  loadDataAlly(brand: string) {
    if (isPlatformBrowser(this.platformId)) {
      if (brand === 'enko') {
        document.documentElement.style.setProperty('--color-primary', '#8741A5', 'important');
        document.documentElement.style.setProperty('--color-secondary', '#F98232', 'important');
        document.documentElement.style.setProperty('--color-button', '#F98232', 'important');
        document.documentElement.style.setProperty('--color-text-button', '#fff', 'important');
        document.documentElement.style.setProperty('--color-background-banner', '#F7F7F7', 'important');
        this._cookieService.put('primary-color', '#8741A5');
      } else {
        this.allyService.getInfoOfAlly(brand).subscribe(
          (res: AllyModel) => {
            this.landingTemplate = res.landing_template;
            this.allyDetail = res;
            this._cookieService.put(DATA_LOCALSTORAGE.CODE_ALLY, res.code);
            document.documentElement.style.setProperty('--color-primary', res.primary_color, 'important');
            document.documentElement.style.setProperty('--color-secondary', res.secondary_color, 'important');
            document.documentElement.style.setProperty('--color-button', res.button_color, 'important');
            document.documentElement.style.setProperty('--color-text-button', res.button_text_color, 'important');
            document.documentElement.style.setProperty('--color-background-banner', res.background_color, 'important');
            document.documentElement.style.setProperty('--title-bold-font', res.section_title_font, 'important');
            document.documentElement.style.setProperty(
              '--subtitle-medium-font',
              res.section_subtitle_font,
              'important'
            );
            document.documentElement.style.setProperty('--body-font', res.section_body_font, 'important');
            document.documentElement.style.setProperty('--color-slider', res.primary_color, 'important');
            this._cookieService.put('primary-color', res.primary_color);
          },
          (error) => {
            // this.router.navigate([`/${this.country}/enko`]);
            this.router.navigate([`/`]);
            // this.ngOnInit();
          }
        );
      }
    }
  }
}
