import { EventEmitter, Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import { ShopPoint } from 'src/app/models/shop/shop.model';

@Injectable({
  providedIn: 'root'
})
export class ShopService {
  private readonly url: string;
  changeEvent = new EventEmitter<boolean>()
  changeEventFinalPart = new EventEmitter<boolean>()

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.url;
  }

  getUserShopPoints(): Observable<ShopPoint> {
    const url = `${this.url}/api/shop_points/`;
    return this.http.get<ShopPoint>(url)
  }

  uploadAssignment(partId: number, files: any): Observable<any> {
    const url = `${this.url}/api/parts/${partId}/upload_assignment/`;
    return this.http.post<any>(url, files);
  }

  /**
   * Method for called update Component Main
   */
  updateComponent(){
    this.changeEvent.emit(true)
  }

  updateComponentFinalPart(){
    this.changeEventFinalPart.emit(true)
  }
}
