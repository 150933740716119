import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {RegisterFacebookModel, RegisterModel, RegisterSimpleModel} from "../../models/auth/register.model";
import {DATA_LOCALSTORAGE} from "../../utils/dataLocalstorage";
import {Observable, Subject} from "rxjs";
import {ResetLoginPin, UserModel} from "../../models/auth/user.model";
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly url: string;

  constructor(
    private http: HttpClient,
    private _cookieService: CookieService
  ) {
    this.url = environment.url;
  }

  registerUser(user: RegisterModel): Observable<UserModel> {
    const url = `${this.url}/api/${this._cookieService.get(DATA_LOCALSTORAGE.COUNTRY)}/register/`;
    return this.http.post<UserModel>(url, user)
  }

  simpleRegisterUser(user: RegisterSimpleModel): Observable<UserModel> {
    const url = `${this.url}/api/${this._cookieService.get(DATA_LOCALSTORAGE.COUNTRY)}/simple_register/`;
    return this.http.post<UserModel>(url, user)
  }

  registerUserFacebook(user: RegisterFacebookModel): Observable<UserModel>{
    const url = `${this.url}/api/${this._cookieService.get(DATA_LOCALSTORAGE.COUNTRY)}/fb_register/`;
    return this.http.post<UserModel>(url, user)
  }

  signIn(phone_number?: string, login_pin?: string, uid?: string): Observable<UserModel> {
    const url = `${this.url}/api/${this._cookieService.get(DATA_LOCALSTORAGE.COUNTRY)}/login/`
    if (login_pin == null) return this.http.post<UserModel>(url, {'phone_number': phone_number})
    else return this.http.post<UserModel>(url, {'phone_number': phone_number, 'login_pin': login_pin})
  }

  sendCodeForWhatsApp(phone_number?: string): Observable<UserModel> {
    const url = `${this.url}/api/wa_verification_code/`;
    return this.http.post<UserModel>(url, {'phone_number':phone_number})
  }

  verifyCodeForWhatsApp(phone_number: string, code: string): Observable<UserModel> {
    const url = `${this.url}/api/wa_validation/`;
    return this.http.post<UserModel>(url, {'phone_number':phone_number, 'verification_code':code})
  }

  resetLoginPin(phone_number?: string): Observable<ResetLoginPin> {
    const url = `${this.url}/api/mx/reset_login_pin/`;
    return this.http.put<ResetLoginPin>(url, {'phone_number':phone_number})
  }
}
