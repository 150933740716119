<div class="card-lesson" (click)="modalTestimony(testimonial)">
    <img class="card-lesson-img" [src]="testimonial.image | safeHtml:'url'"
        alt="Card image cap">
    <div class="card-body">
        <a class="pointer">
            <div class="row">
                <div class="col">
                    <a class="title-lesson">
                        {{testimonial.description}}
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    <p><span class="dot" style="background-color: {{testimonial.module_color}}"></span>{{testimonial.module}}
                    </p>
                </div>
            </div>
        </a>
    </div>
</div>