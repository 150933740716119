import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import { PaginatorModule, PaginatorLessonFavorite } from "src/app/models/paginator/paginator.model";
import { ModuleModel } from "../../models/module/module.model";
import {map} from "rxjs/operators";
import { LessonModel } from 'src/app/models/lesson/lesson.model';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  private readonly url: string;

  constructor(
    private http: HttpClient,
  ) {
    this.url = environment.url;
  }

  getModules(): Observable<Array<ModuleModel>> {
    const url = `${this.url}/admin/lesson_modules/`;
    return this.http.get<PaginatorModule>(url)
      .pipe(
        map(response => response.results)
      )
  }


  getModulesApi(): Observable<Array<ModuleModel>> {
    const url = `${this.url}/api/lesson_modules/`;
    return this.http.get<PaginatorModule>(url)
      .pipe(
        map(response => response.results)
      )
  }

  getLessonModule(id: number): Observable<Array<LessonModel>>{
    const url = `${this.url}/admin/lesson_modules/${id}/lessons/`;
    return this.http.get<PaginatorLessonFavorite>(url)
      .pipe(
        map(response => response.results)
      )

  }

  getModuleId(id: number): Observable<ModuleModel> {
    const url = `${this.url}/api/lesson_modules/${id}/`;
    return this.http.get<ModuleModel>(url)
  }
  
}
