import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {QuicklinkStrategy} from "ngx-quicklink";
import { AuthGuardService } from "./core/guards/authGuard.service";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/country/country.module').then(m => m.CountryModule)
  },
  {
    path: ':country/:brand',
    pathMatch: 'prefix',
    loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: ':country/:brand/seccion',
    pathMatch: 'prefix',
    loadChildren: () => import('./modules/content/content.module').then(m => m.ContentModule)
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/',
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      preloadingStrategy: QuicklinkStrategy,
      paramsInheritanceStrategy: "always"
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
