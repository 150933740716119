// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyC4-Q_Agga392cX32bySqdvrny5Bla6tn4',
    authDomain: 'enko-2020.firebaseapp.com',
    databaseURL: 'https://enko-2020.firebaseio.com',
    projectId: 'enko-2020',
    storageBucket: 'enko-2020.appspot.com',
    messagingSenderId: '1065484911624',
    appId: '1:1065484911624:web:b39e192821220d3c157dbd'
  },
  //url: 'https://api.enko.org',
  url: 'https://backend.enko.space', // https://backend.enko.space
  url_service_acelera: 'https://app.acelera.lat/GetReports.ashx',
  url_S3assets: 'https://d9q73uveprt4n.cloudfront.net/games/Enko_Assets/',
  pwa: 'https://m.enko.org/home/tabs/register',
  //pwa: 'https://m.enko.org/home/tabs/register'
  loginPassword: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
