import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TestimonialsModel } from "../../../models/testimonials-modules/testimonials.model";

@Component({
  selector: 'app-modal-testimonials',
  templateUrl: './modal-testimonials.component.html',
  styleUrls: ['./modal-testimonials.component.scss']
})
export class ModalTestimonialsComponent implements OnInit {
  @Input() testimony: TestimonialsModel;
  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  getVideoUrl(){
    return `${this.testimony.video}`;
  }


  closeModal(){
    this.activeModal.dismiss()
  }

}
