<div class="row">
    <div class="col col-12 carousel-desktop">
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner" *ngIf="!allyDetail || allyDetail.desktop_carousel_images.length === 0">
                <div class="carousel-item" *ngFor="let banner of arrayImage; let index = index;let isFirst = first" [ngClass]="{active:isFirst}">
                    <img class="d-block w-100" [src]="banner.image" alt="banner">
                </div>
            </div>
            <div class="carousel-inner" *ngIf="allyDetail">
                <div class="carousel-item" *ngFor="let ally of allyDetail.desktop_carousel_images; let index = index;let isFirst = first" [ngClass]="{active:isFirst}">
                    <img class="d-block w-100" [src]="ally.image" alt="banner">
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
    <div class="col col-12 carousel-mobile">
        <div id="carouselControlsMobile" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner" *ngIf="!allyDetail">
                <div class="carousel-item" *ngFor="let banner of arrayImageMobile; let index = index;let isFirst = first" [ngClass]="{active:isFirst}">
                    <img class="d-block w-100" [src]="banner.image" alt="banner">
                </div>
            </div>
            <div class="carousel-inner" *ngIf="allyDetail">
                <div class="carousel-item" *ngFor="let ally of allyDetail.mobile_carousel_images; let index = index;let isFirst = first" [ngClass]="{active:isFirst}">
                    <img class="d-block w-100" [src]="ally.image" alt="banner">
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselControlsMobile" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselControlsMobile" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</div>