import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

// Environment
import {environment} from "../environments/environment";

// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from "@angular/fire/auth";

// Routing
import { AppRoutingModule } from './app-routing.module';

// Modules
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {SharedModule} from "./shared/shared.module";
import { CookieModule } from 'ngx-cookie';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QuicklinkModule } from 'ngx-quicklink';
import { CarouselModule } from "ngx-owl-carousel-o";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserTransferStateModule, TransferState } from '@angular/platform-browser';
import { BrowserStateInterceptor } from "./core/interceptor/browserstate.interceptor";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'Enko' }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    HttpClientModule,
    QuicklinkModule,
    SharedModule,
    FontAwesomeModule,
    NoopAnimationsModule,
    CarouselModule,
    BrowserTransferStateModule,
    CookieModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: BrowserStateInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
