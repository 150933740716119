import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { StorageService} from "../../services";
import { DATA_LOCALSTORAGE } from "../../utils/dataLocalstorage";
import { CookieService } from 'ngx-cookie';
import { Router, ActivatedRoute } from "@angular/router";
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class BrowserStateInterceptor implements HttpInterceptor {
  token:string;
  constructor(
    private transferState: TransferState,
    private _storageService: StorageService,
    private _cookieService: CookieService,
    private router: Router,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.token = this._storageService.get(DATA_LOCALSTORAGE.TOKEN);

    const storedResponse: string = this.transferState.get(makeStateKey(req.url), null);

    if (storedResponse) {
      const response = new HttpResponse({ body: storedResponse, status: 200 });
      return of(response);
    }


    if( (req.url.includes('countries') || 
        req.url.includes('most_viewed_lessons') ||
        req.url.includes('new_lessons') ||
        req.url.includes('homepages') || 
        req.url.includes('landing_pages')) && !this.token){
      return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            this.transferState.set(makeStateKey(req.url), event.body);
          }
        })
      );
    }

    if(req.url.includes('acelera')){
      return next.handle(req)
    }
      
    if (this.token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`
        }
      });
    }
  
    return next.handle(req).pipe(
      tap(
        event => {
         
        },
        err => {
            if (err.status === 401 || err.status === 403){
              this.router.navigate([`${this._cookieService.get(DATA_LOCALSTORAGE.COUNTRY)}/${this._cookieService.get(DATA_LOCALSTORAGE.CODE_ALLY)}/login`],);
            }
        }
      )
    );

  }
}