<footer class="container-fluid footer">
  <div class="row justify-content-center">

    <div class="col-lg-3 col-md-4 col text-center" *ngIf="!allyDetail || (allyDetail && allyDetail.navbar_enko_logo_enabled)">
      <p class="footer-text affogato-medium"><img src="{{s3UrlImage}}Logos/logo-enko-gray.png" loading="lazy" alt="Enko" class="logo-enko"> Con el apoyo de <img src="{{s3UrlImage}}Logos/logo-visa-white.svg" alt="Visa"></p>
    </div>
    <div class="col col-12 col-md-8 text-center" *ngIf="allyDetail && !allyDetail.navbar_enko_logo_enabled">
      <p class="footer-text">
        <img [src]="allyDetail.footer_logo" loading="lazy" class="logo-ally" alt="Enko">
      </p>
    </div>
    <!-- <div class="col-lg-4 col-md-5 col text-center" *ngIf="!allyDetail">
      <p class="footer-text affogato-medium"><img src="{{s3UrlImage}}Logos/logo-enko-gray.png" loading="lazy" alt="Enko" class="logo-enko"> Con el apoyo de <img src="{{s3UrlImage}}Logos/logo-visa-white.svg" alt="Visa"></p>
    </div>
    <div class="col-lg-4 col-md-5 col text-center" *ngIf="allyDetail">
      <p *ngIf="allyDetail.footer_enko_logo_enabled" class="footer-text affogato-medium"><img src="{{s3UrlImage}}Logos/logo-enko-gray.png" loading="lazy" alt="Enko" class="logo-enko"> Con el apoyo de <img src="{{s3UrlImage}}Logos/logo-visa-white.svg" alt="Visa"></p>
      <img *ngIf="!allyDetail.footer_enko_logo_enabled" src="allyDetail.footer_logo" alt="footer-image">
    </div> -->
  </div>
</footer>
