<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h3 class="affogato-bold">Bienvenido a ENKO-FUNDES</h3>
        </div>
        <div class="col-12">
            <span class="affogato-medium">Información Básica sobre Cookies</span>
            <p class="proxima-nova">
                Este sitio web utiliza cookies tanto propias como de terceros, lo que implica la realización de tratamientos de datos de carácter personal con arreglo al marco jurídico mexicano. 
                A continuación se proporciona información básica o esencial sobre las cookies que empleamos nosotros y nuestros socios de forma específica.
            </p>
        </div>
        <div class="col-12">
            <span class="affogato-medium">¿Qué son las cookies y para qué las utilizamos?</span>
            <p class="proxima-nova">
                Las cookies y otras tecnologías similares son pequeños archivos que se instalan en el navegador o dispositivo de un usuario que accede a páginas web por medio de un ordenador, terminal o dispositivo móvil, registran datos de navegación y otros, y permiten ser accedidos o recuperados posteriormente por nosotros o por terceros. Este sitio web utiliza cookies para las siguientes finalidades:
            </p>
            <ul class="list">
                <li>
                    Realizar un análisis del tráfico y la navegación (tanto páginas vistas como parámetros de búsqueda e interacciones) y la realización de estudios estadísticos. Dicho análisis es en principio de datos agregados, por tanto sin vinculación con un usuario concreto identificado, salvo que se identifique posteriormente. Para lo anterior utilizamos cookies propias y/o de terceros.
                </li>
                <li>
                    Para identificar usuarios y mantener abierta la sesión de usuarios registrado, pudiendo vincular entonces sus hábitos de navegación, contenidos publicitarios y no publicitarios mostrados, interacciones y otras acciones realizadas dentro del sitio web con nuestros datos internos de registro, todo ello igualmente con cookies propias o de terceros.
                </li>
                <li>
                    Para compartir contenidos en redes sociales y poder mostrar contenido audiovisual mediante técnicas de transclusión (framing) en las mismas, sin que ello permita acceder a más información sobre su perfil en dichas redes que el que voluntariamente y por medio de su configuración en dichas redes y servicios sea público a terceros sin restricciones. Para ello se usan cookies de terceros.
                </li>
                <li>
                        Para la identificación, almacenamiento y recuperación de datos para seleccionar y mostrar contenidos publicitarios y no publicitarios, medir el rendimiento de los anteriores, realizar estudios de mercado para generar información sobre audiencias, y para desarrollar y mejorar servicios. El tratamiento de estos datos podrá implicar la realización de un perfilado y segmentación de usuarios, con base a intereses, preferencias, factores demográficos, de localización, sexo y otros del usuario y terceros o los dispositivos de éstos utilizados para acceder a Internet, mediante técnicas de análisis e interpretación de datos, la realización de inferencias y la aplicación de modelos predictivos. El tratamiento de dichas cookies, la información sobre sus finalidades concretas y su clasificación, la relación de operadores que acceden y realizan el tratamiento de los datos, y la gestión de sus preferencias y opciones de consentimiento se realiza por medio de esta plataforma de gestión de consentimiento (consent management platform o CMP) que cumple con los estándares de la industria de publicidad y marketing digital representada por Interactive Advertising Bureau (IAB).
                </li>
            </ul>
        </div>
        <div class="col-12">
            <span class="affogato-medium">Licitud del tratamiento de los datos</span>
            <p class="proxima-nova">
                De conformidad con lo dispuesto en la normatividad mexicana, FUNDES – ENKO está habilitada para el uso de dispositivos, técnicas o tecnologías de almacenamiento y recuperación de datos (“cookies”) siempre y cuando se obtenga el consentimiento de los usuarios tras haberles proporcionado información clara y completa sobre su utilización. Lo anterior se aplica tanto a nuestras cookies como a las de terceros (nuestros socios o proveedores).
            </p>
            <p>
                El consentimiento del usuario es, por tanto, la principal, aunque no única base jurídica que legitima tanto la instalación y uso de cookies como el acceso a los datos por éstas obtenidos y su posterior tratamiento. Dicho consentimiento se obtiene al continuar navegando en nuestra página
            </p>
        </div>
    </div>
</div>