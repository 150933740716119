<div class="container">
  <div class="row justify-content-center">
    <div class="col col-lg-12 col-12">
      <owl-carousel-o [options]="customOptions" id="histories">
        <ng-container *ngFor="let history of histories">
          <ng-template carouselSlide>
            <div class="card-history">
              <div class="card-box text-center">
                <img [src]="history.image | safeHtml:'url'" loading="lazy" [alt]="history.name" class="card-box-image">
                <p class="card-box-description">
                  {{history.text}}
                </p>
                <p class="card-box-autor">{{history.occupation}}</p>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</div>
