import { Component, OnInit } from '@angular/core';
import {BlogService} from "../../../services";
import {BlogResponseModel} from "../../../models/blog/blog.model";

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  arrayBlog: Array<BlogResponseModel> = [];

  constructor(
    private blogService: BlogService
  ) { }

  ngOnInit(): void {
    this.loadBlog();
  }

  /**
   * Metodo que carga todas las entradas del blog
   */
  loadBlog() {
    this.blogService.getListBlog()
      .subscribe(res => {
      this.arrayBlog = res.results;
    })
  }
}
