<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h5 class="affogato-bold">Terminos y condiciones</h5>
        </div>
        <div class="col-12">
            <span class="affogato-medium">Introducción</span>
            <p class="proxima-nova">Este sitio WEB es operado por Fundación para el Desarrollo Sostenible en México S.C, 
                en  adelante FUNDES y se encuentra a su disposición para fines de su desarrollo empresarial, 
                al acceder o  utilizar este sitio usted acepta estos Términos y Condiciones de Uso. 
                Si usted no acepta los  Términos y Condiciones de Uso, usted no puede utilizar este Sitio Web.</p>
            <p class="proxima-nova">
                Los presentes términos y condiciones de uso regulan el acceso o el uso que usted haga como  persona o empresa, 
                del contenido de esta página puestos a su disposición por la Fundación para el  Desarrollo Sostenible en México S.C. con domicilio en Calle Tlaxcala 25, interior 122, Roma Sur, Cuauhtémoc, Ciudad de México
                ,México. 
            </p>
            <p class="proxima-nova">La Fundación para el Desarrollo Sostenible en México S.C sus siglas FUNDES, es una sociedad  civil legalmente constituida de conformidad con las Leyes de los Estados Unidos Mexicanos y cuya  principal actividad es la del desarrollo empresarial de MIPYMES. 
                Nos enfocamos en brindar servicios  asesoría presencial y digital, a través de diversos medios, 
                uno de los cuales es la Plataforma ENKO. </p>
        </div>
        <div class="col col-12">
            <span class="affogato-medium">Marcas</span>
            <p class="proxima-nova">Este Sitio Web incluye y presenta logos, logotipos y otras marcas comerciales y marcas de  servicio que son propiedad de, o son licenciadas a, varias afiliadas de FUNDES. 
                El Sitio Web también puede  incluir marcas comerciales o marcas de servicio de terceros. 
                Todas esas marcas comerciales son propiedad de sus respectivos titulares y Ud. acuerda no usar o mostrar las mismas de cualquier forma sin  la autorización previa por escrito del propietario de la marca comercial en cuestión.</p>
            <ul class="list-none">
                <li class="affogato-medium">Propiedad intelectual e industrial</li>
                <p class="proxima-nova">
                    El contenido, organización, gráficas, diseño, compilación y otros aspectos del Sitio se encuentran  protegidos por leyes de Propiedad Intelectual. Su copia, redistribución, uso o publicación, total o parcial,  está prohibida por la ley. 
                    La publicación o transmisión de información o documentos en el Sitio, no constituye una renuncia de cualquier derecho relacionado con tales documentos o información. </p>
                <li class="affogato-medium">Contenido del visitante</li>
                <p class="proxima-nova">
                    La Página Web podría permitir el ingreso de información, datos, textos, fotografías, sea que se  fijen públicamente o se transmitan privadamente. 
                    En tal caso, dicho Contenido es únicamente  responsabilidad de la persona que lo originó. Esto significa que usted (y no FUNDES) es enteramente  responsable por todo el Contenido que usted cargue, publique, envíe por correo electrónico, transmita o  de cualquier forma ponga a disposición a través del Sitio Web. 
                    Nosotros podremos controlar el Contenido publicado por Ud. a través del Sitio Web, sin embargo no garantizamos su exactitud, integridad o calidad.  No obstante, el Sitio Web contará con un moderador (FUNDES) que estará facultado a eliminar el </p>
                <p class="proxima-nova">
                    Contenido que le parezca impropio. Asimismo, FUNDES eliminará todo Contenido ante la denuncia de  aquellos terceros que se vean afectados y/o lesionados en sus derechos. 
                    Bajo ninguna circunstancia  FUNDES será responsable en cualquier forma por cualquier Contenido, 
                    incluyendo, pero sin limitarse a  cualquier error u omisión en cualquier Contenido, o por cualquier pérdida o daño de cualquier tipo ocasionado como resultado del uso de cualquier Contenido publicado, 
                    enviado a través de correo  electrónico, transmitido o puesto a disposición a través del Sitio Web.</p>
                <p class="proxima-nova">
                    Nosotros nos reservamos el derecho de eliminar el acceso y/o uso del Sitio Web a cualquier usuario y/o visitante del mismo (“Visitante”) que no respete los términos y condiciones establecidos en el  presente. 
                    Asimismo, nosotros nos reservamos el derecho de eliminar cualquier mensaje que: 
                </p>
                <ul class="list">
                    <li class="proxima-nova">Sea ilegal, peligroso, amenazante, abusivo, hostigador, tortuoso, difamatorio, vulgar,  obsceno, calumnioso, invasivo de la privacidad de terceros, odioso, discriminatorio, o que de cualquier  forma viole derechos de terceros y/o disposiciones legales aplicables.</li>
                    <li class="proxima-nova">Sea contrario a la moral y las buenas costumbres.</li>
                    <li class="proxima-nova">Ofrezca cualquier actividad que sea lucrativa para el Visitante.</li>
                </ul>
                <p>Usted se obliga a no utilizar el Sitio Web para lo siguiente:</p>
                <ul class="list">
                    <li class="proxima-nova">Dañar a menores de edad en cualquier forma.</li>
                    <li class="proxima-nova">Hacerse pasar por alguna persona o entidad, incluyendo, pero sin limitarse, a un  funcionario o empleado de FUNDES o hacer declaraciones falsas, o de cualquier otra forma falsificar su  asociación a alguna persona o entidad.</li>
                    <li class="proxima-nova">Falsificar encabezados o de cualquier otra forma manipular identificadores para  desviar el origen de algún Contenido transmitido por medio del Sitio Web.</li>
                    <li class="proxima-nova">Cargar ("upload"), publicar, enviar por correo electrónico, transmitir, o de cualquier  otra forma poner a disposición algún Contenido del cual no tiene el derecho de transmitir por ley o bajo  relación contractual o fiduciaria (tal como información interna, de propiedad y confidencial adquirida o  entregada como parte de las relaciones de empleo o bajo contratos de confidencialidad).</li>
                    <li class="proxima-nova">Cargar, publicar, enviar por correo electrónico, transmitir, o de cualquier otra forma  poner a disposición algún Contenido que viole alguna patente, marca, secreto comercial, derecho de autor  o cualquier derecho de propiedad ("Derechos") de algún tercero.</li>
                    <li class="proxima-nova">Cargar, publicar, enviar por correo electrónico, transmitir o de cualquier otra forma  poner a disposición cualquier anuncio no solicitado o no autorizado, materiales promocionales, correo no  solicitado ("junk mail", "spam"), cartas en cadena ("chain letters"), esquemas de pirámides ("pyramid  schemes") o cualquier otra forma de solicitud.</li>
                    <li class="proxima-nova">Cargar ("upload"), publicar, enviar por correo electrónico, transmitir, o de cualquier  otra forma poner a disposición algún material que contenga virus de software, o cualquier otro código de  computadora, archivos o programas diseñados para interrumpir, destruir o limitar el funcionamiento de  algún software, hardware o equipo de telecomunicaciones.</li>
                    <li class="proxima-nova">Interrumpir el flujo normal de diálogo, hacer que una pantalla se mueva ("scroll") más  rápido de lo que otros Visitantes pueden manejar, o de cualquier otra forma actuar de manera que afecte  negativamente la habilidad de otros Visitantes para vincularse en intercambios de tiempo reales.</li>
                    <li class="proxima-nova">Interferir o interrumpir el Sitio Web, servidores, o redes conectadas al Sitio Web, o  desobedecer cualquier requisito, procedimiento, política o regulación de redes conectadas al Sitio Web.</li>
                    <li class="proxima-nova">Violar con o sin intención alguna ley local, estatal, nacional o internacional aplicable y  cualquier otra regulación.</li> 
                    <ul class="list">
                        <li class="proxima-nova">Acechar o de cualquier otra forma hostigar a un tercero.</li>
                        <li class="proxima-nova">Colectar o guardar datos personales acerca de otros Visitantes.</li>
                        <li class="proxima-nova"> Publicar datos personales sin el consentimiento de la persona involucrada.</li>
                    </ul>
                </ul>
            </ul>
            <p class="proxima-nova">FUNDES en ningún caso será responsable por la destrucción o eliminación de la información que  los Visitantes incluyan en sus mensajes.</p>
        </div>
        <div class="col col-12">
            <span class="affogato-medium">Jurisdicción y ley aplicable</span>
            <p class="proxima-nova">
                Este acuerdo estará regido por las leyes de los Estados Unidos Mexicanos. Cualquier  controversia derivada del presente acuerdo, su existencia, validez, interpretación, alcance o cumplimiento,  será sometida a las leyes aplicables y a los Tribunales del Distrito Federal competentes para su  conocimiento. 
            </p>
        </div>
        <div class="col col-12">
            <span class="affogato-medium">Modificaciones a las condiciones de uso: </span>
            <p class="proxima-nova">
                FUNDES se reserva el derecho de actualizar y modificar en cualquier momento y de cualquier  forma, de manera unilateral y sin previo aviso, las presentes condiciones de uso y los contenidos de este Sitio Web a su libre elección y en cualquier momento y los mismos estarán vigentes  una vez hayan publicado en el Sitio Web. El Usuario se compromete a revisar periódicamente esta sección  para estar informado de tales modificaciones y cada nuevo acceso del Usuario a este Sitio Web será considerado una aceptación tácita de las nuevas condiciones. 
            </p>
        </div>
        <div class="col col-12">
            <span class="affogato-medium">Aceptación de Términos:</span>
            <p class="proxima-nova">
                Si el Usuario accede al sitio Web lo hace bajo su total responsabilidad y por tanto, el Usuario  acepta plenamente y sin reservas el contenido de los términos y condiciones de uso del sitio Web. 
            </p>
        </div>
        <div class="col col-12">
            <p class="proxima-nova">
                (Si está ubicado en Colombia las siguientes secciones se aplican a las personas naturales o  jurídicas que usen el Sitio Web) 
            </p>
        </div>
        <div class="col col-12">
            <p class="proxima-nova">
                La prestación del servicio del Sitio Web por parte de FUNDES es de carácter libre y gratuito para  el Usuario. FUNDES no persigue ningún lucro, ganancia o interés comercial con los contenidos o links que  se publican en este Sitio Web y en las páginas web de otras dependencias o entidades adscritas y  vinculadas, a los cuales se accede a través del Sitio Web.
            </p>
        </div>
    </div>
</div>