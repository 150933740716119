<div class="container" *ngIf="(detailBlog$ | async) as detailBlog">
  <div class="row">
    <div class="col text-center">
      <h1>{{detailBlog.title}}</h1>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div [innerHTML]="detailBlog.content | safeHtml: 'html'"></div>
    </div>
  </div>
</div>
