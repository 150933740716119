import { Component, Input ,OnInit } from '@angular/core';
import {OwlOptions} from "ngx-owl-carousel-o";
import { TestimonialsModel } from "../../../models/testimonials-modules/testimonials.model";

@Component({
  selector: 'app-slider-testimonials',
  templateUrl: './slider-testimonials.component.html',
  styleUrls: ['./slider-testimonials.component.scss']
})
export class SliderTestimonialsComponent implements OnInit {
  @Input() arrayTestimonials: Array<TestimonialsModel>;
  customOptions: OwlOptions = {
    loop: true,
    autoWidth: true,
    autoplay: false,
    autoplayHoverPause: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    mergeFit: false,
    navText: ["<", ">"],
    /* stagePadding: 50, */
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 4
      },
    },
    nav: true
  }
  constructor() { }

  ngOnInit(): void {
  }

}
