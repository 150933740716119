import { Component, OnInit, Input } from '@angular/core';
import { HOME_LABELS, HOME_COMEX__CATEGORIES, HOME_DEFAULT_CATEGORIES, HOME_COMPARTAMOS_CATEGORIES} from "../../../utils/labels";
import { ICON } from "../../../utils/icons";
import { AllyModel } from "../../../models/ally/ally.model";
import { environment } from ".././../../../environments/environment";
import { ActivatedRoute } from '@angular/router';
import { AllyService } from 'src/app/services';

interface categories {
  image:string;
  video:string;
  text_button: string;
  icon_button: any;
  category: Array<category>;
}
interface category {
  img?: string;
  text: string;
}


@Component({
  selector: 'app-block-category',
  templateUrl: './block-category.component.html',
  styleUrls: ['./block-category.component.scss']
})
export class BlockCategoryComponent implements OnInit {
  @Input() ally: AllyModel;
  brand:string;
  allyDetail: AllyModel;
  arrayImage: Array<any> = [] ;
  dataCategory: categories = {
    image: `${environment.url_S3assets}Homepage_Images/principal_category_image.png`,
    video: `https://enko-solutions.s3.amazonaws.com/videos/5+febrero+-+como+funciona+ENKO.mp4`,
    text_button: HOME_LABELS.BUTTON_WATCH_HOW_TO_WORK,
    icon_button: ICON.PLAY,
    category:[]
  }

  constructor(
    private route: ActivatedRoute,
    private allyService: AllyService,
  ) { 
    this.route.paramMap.subscribe(params => {
      this.brand = params.get('brand');
    })
     
    this.dataCategory.category = [];
    
  }

  ngOnInit(): void {
    this.getLessonModules(this.brand);
    if(this.ally && this.ally.name === "Bavaria"){
      this.allyDetail.lesson_modules.forEach(element=>{
        if(element.name === 'Mercadotecnia'){
          element.name = 'Promoción a tus productos';
        }
      })
      /*
      this.dataCategory.category.forEach(element => {
        if(element.text === 'Mercadotecnia'){
          element.text = 'Promoción a tus productos';
        }
      } 
      );*/
    }

    if(this.ally === undefined){
      this.brand = 'enko';
      this.getLessonModules(this.brand);
      return;
    }
    /*
    if(this.ally === null){
      this.dataCategory.category = HOME_DEFAULT_CATEGORIES
      return;
    }
    this.getCategoriesByAlly();
    */
  }
  
  getCategoriesByAlly(){  
    /*
    if(this.ally.code != 'comex'){
      this.dataCategory.category = HOME_DEFAULT_CATEGORIES
    }
    if(this.ally.code === 'comex'){
      this.dataCategory.category = HOME_COMEX__CATEGORIES
    }
    if(this.ally.code === 'compartfon' || this.ally.code === 'compartamosbanco'){
      this.dataCategory.category = HOME_COMPARTAMOS_CATEGORIES
    }
    */
  }
  

  getVideoUrl(){
    return `${this.ally.section_video}`;
  }


  getLessonModules(ally: string): void{
    this.allyService.getInfoOfAlly(ally).subscribe(res => {
      this.allyDetail = res;
    })
  }


}
