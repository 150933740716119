import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {CountryModel} from "../../models/country/country.model";
import {ReviewLessonModel} from "../../models/review-lesson/reviewLesson.model";
import { GeographicAreasModel, QuestionsBussinesModel, ZipCodeModel } from 'src/app/models/profile/profile.model';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  private readonly url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = environment.url;
  }

  /**
   * Metodo que consulta todos los paises disponibles en BACKEND
   */
  getCountries(): Observable<Array<CountryModel>>{
    return this.http.get<Array<CountryModel>>(`${this.url}/api/catalogs/countries/`);
  }

  /**
   * Metodo que consulta todos los reviews de una lección
  */
  getReviewLesson(): Observable<Array<ReviewLessonModel>>{
    return this.http.get<Array<ReviewLessonModel>>(`${this.url}/api/catalogs/reviews/`);
  }

  /**
   * Metodo que regresa el catalogo de todos los tipos de negocio
  */
  getBusinessType(): Observable<Array<QuestionsBussinesModel>>{
    return this.http.get<Array<QuestionsBussinesModel>>(`${this.url}/admin/catalogs/business_types/`);
  }

  /**
   * Metodo que regresa el catalogo de número de empleados
  */
  getEmployeeNumber(): Observable<Array<QuestionsBussinesModel>>{
    return this.http.get<Array<QuestionsBussinesModel>>(`${this.url}/admin/catalogs/employee_numbers/`);
  }


  /**
   * Metodo que regresa el catalogo de ingresos mensuales
  */
  getWeeklyIncomes(): Observable<Array<QuestionsBussinesModel>>{
    return this.http.get<Array<QuestionsBussinesModel>>(`${this.url}/api/catalogs/weekly_incomes/`);
  }
  
  /**
   * Metodo que regresa el catalogo de los tipos de mejora de un negocio
  */
  getBusinessImprovements(): Observable<Array<QuestionsBussinesModel>>{
    return this.http.get<Array<QuestionsBussinesModel>>(`${this.url}/admin/catalogs/business_improvements/`);
  }


  /**
   * Metodo que regresa el catalogo de los niveles educativos
  */
  getEducationLevels(): Observable<Array<QuestionsBussinesModel>>{
  return this.http.get<Array<QuestionsBussinesModel>>(`${this.url}/admin/catalogs/education_levels/`);
  }

  /**
   * Metodo que regresa el catalogo de las areas geograficas
  */
  getGeographicAreas(): Observable<Array<GeographicAreasModel>>{
  return this.http.get<Array<GeographicAreasModel>>(`${this.url}/api/catalogs/geographic_areas/`);
  }

  /**
   * Metodo que regresa si el código postal es valido
  */
  getZipCode(zipcode): Observable<ZipCodeModel>{
    console.log('http',zipcode)
    return this.http.get<ZipCodeModel>(`${this.url}/api/catalogs/postal_codes/?postal_code=${zipcode}`);
  }

}
